import {storyAPI} from "../../api/storyAPI";
import {createSlice} from "@reduxjs/toolkit";

const initialTreeState = {
    storyTree: [],
    isStoryTreeLoading: true,
}

export const fetchStoryTree = () => async (dispatch) => {
    dispatch(storyTreeLoading(true))
    const tree = await storyAPI.foldersTree()
        .then(({data}) => data?.data)
        .catch(err => [])
    if (!Array.isArray(tree)) {
        dispatch(setStoryTree([]))
        dispatch(storyTreeLoading(false))
        return
    }
    const storyTree = tree.map(storyTreeMap)
    dispatch(setStoryTree(storyTree))
    dispatch(storyTreeLoading(false))
}

const storyTree = createSlice({
    name: 'story/tree',
    initialState: initialTreeState,
    reducers: {
        setStoryTree(state, action) {
            state.storyTree = action.payload
        },
        storyTreeLoading(state, action) {
            state.isStoryTreeLoading = action.payload
        }
    },
})

const {actions: treeActions, reducer: treeReducer} = storyTree
export const {
    setStoryTree, storyTreeLoading
} = treeActions

export default treeReducer

const storyTreeMap = ({children = [], name, id, guid, ...obj}) => ({
    ...obj,
    title: name,
    key: guid,
    id,
    children: children.map(storyTreeMap)
})
