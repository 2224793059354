import {configureStore, combineReducers, getDefaultMiddleware} from "@reduxjs/toolkit";
import {creativeListReducer, creativeTreeReducer} from "./modules/creative";
import {storyConfigReducer} from "./modules/story"
import storyTreeReducer from "./modules/story-tree"
import managerReducer from "./modules/manager";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {storyConfigListReducer} from "./modules/story-config/story-config-persisted-list";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['manager']
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
    creativeTreeState: creativeTreeReducer,
    creativeListState: creativeListReducer,
    storyTreeState: storyTreeReducer,
    storyConfigState: storyConfigReducer,
    storyConfigList: storyConfigListReducer,
    manager: managerReducer
}))

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
    })
})

export const persistor = persistStore(store)

export default store

