import styled from "styled-components";
import {Button} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

const ButtonStyled = styled(Button)`
  && {
    width: 20px;
    height: 20px;
    font-size: 12px;
    vertical-align: middle;
    background-color: #BDC6D1;
    border-color: #B9C0CB;
    color: white;
    &:disabled {
      opacity: .5;
      &:hover {
        color: white;
        background: #BDC6D1;
        border-color: #B9C0CB;
      }
    }
  }
`

export const MinusButton = props => (
    <ButtonStyled size="small" icon={<MinusOutlined style={{fontSize: '12px'}}/>} {...props}/>
)

export const PlusButton = props => (
    <ButtonStyled size="small" icon={<PlusOutlined style={{fontSize: '12px'}}/>} {...props}/>
)
