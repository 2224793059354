import React, {useMemo} from "react";
import {shallowEqual, useSelector} from "react-redux";
import CreativeFoldersTree from "./Creative/CreativeFoldersTree";
import StoryFoldersTree from "./Story/tree/StoryFolderTree";

const ManagerPanel = () => {
    const {
        isCreativeTreeLoading, creativeTree,
        isStoryTreeLoading, storyTree
    } = useSelector(state => ({
        isCreativeTreeLoading: state.creativeTreeState.isCreativeTreeLoading,
        creativeTree: state.creativeTreeState.creativeTree,
        isStoryTreeLoading: state.storyTreeState.isStoryTreeLoading,
        storyTree: state.storyTreeState.storyTree
    }), shallowEqual)

    return (
        <>
            {
                useMemo(() => (
                    <CreativeFoldersTree foldersTree={creativeTree} loading={isCreativeTreeLoading}/>
                ),[creativeTree, isCreativeTreeLoading])
            }
            {
                useMemo(() => (
                    <StoryFoldersTree foldersTree={storyTree} loading={isStoryTreeLoading}/>
                ), [storyTree, isStoryTreeLoading])
            }
        </>
    )
}
export default ManagerPanel
