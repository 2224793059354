import ManagerPanel from "../Manager/ManagerPanel";
import {Layout} from "antd";
import React, {useEffect, useMemo} from "react";
import ManagerView from "../Manager/ManagerView";
import SplitPane from "react-split-pane";
import './../../assets/split-pane.scss'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setSplitPanePosition} from "../../redux/modules/manager";
import {fetchCreativeTree} from "../../redux/modules/creative";
import {fetchStoryTree} from "../../redux/modules/story-tree";

const {Sider} = Layout

const ManagerPage = () => {
    const {splitPanePosition} = useSelector(state => ({
        splitPanePosition: state.manager.splitPane.position,
    }), shallowEqual)
    const dispatch = useDispatch()
    const splitChange = size => {
        dispatch(setSplitPanePosition(size))
    }

    useEffect( () => {
        dispatch(fetchStoryTree())
        dispatch(fetchCreativeTree())
    }, [dispatch])

    return (
        <Layout style={{position: 'relative', width: '100%', height: 'calc(100vh - 60px)'}}>
            <SplitPane
                split="vertical" primary="second"
                defaultSize={splitPanePosition}
                onChange={splitChange}
                pane1Style={{minWidth: 0}}
            >
                <Sider theme="light" width="99%" style={{
                    overflowY: 'auto',
                    height: '100%',
                    boxShadow: '0px 4px 20px rgba(189, 198, 209, 0.2)'
                }}>
                    {useMemo(() => <ManagerPanel/>, [])}
                </Sider>
                <Layout.Content style={{
                    backgroundColor: "#ffffff", color: '#333333',
                    height: '100%', overflowY: 'auto'
                }}>
                    {useMemo(() => <ManagerView/>, [])}
                </Layout.Content>
            </SplitPane>
        </Layout>
    )
}

export default ManagerPage
