import React from "react";
import {Button, Card, Col, Form, Input, Row} from "antd";
import {useAuth} from "../../hooks/use-auth";


const LoginPage = () => {
    const {login} = useAuth()
    const handleSubmit = ({email,password}) => {
        login({email,password})
    }
    return (<Row style={{width: '100%'}}
        justify="center" align="middle"
    >
        <Col><Card>
            <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item name="email" label="E-mail">
                    <Input/>
                </Form.Item>
                <Form.Item name="password" label="Пароль">
                    <Input type="password"/>
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </Card></Col>
    </Row>)
}

export default LoginPage
