import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {randomKey} from "../../../utils/common";
import {storyConfigActions} from "../story";

const rotationsAdapter = createEntityAdapter({
    selectId: model => model.id
})

const rotationsPropertiesAdapter = createEntityAdapter({
    selectId: model => model.id
})

const rotations = createSlice({
    name: 'story/config/rotations',
    initialState: {
        ...rotationsAdapter.getInitialState(),
        properties: rotationsPropertiesAdapter.getInitialState(),
    },
    reducers: {
        setRotations(state, action) {
            const { payload } = action
            let rotationsProperties = []
            const rotations = payload.map(({ properties, guid, ...r }) => {
                const propertyIds = properties.map(p => {
                    const rotationPropertyId = guid + '_' + randomKey(8)
                    rotationsProperties.push({id: rotationPropertyId, ...p})
                    return rotationPropertyId
                })
                return ({ id: guid, propertyIds, ...r })
            })
            rotationsAdapter.setAll(state, rotations)
            rotationsPropertiesAdapter.setAll(state.properties, rotationsProperties)
        },
        updateRotationProperty(state, action) {
            const { payload } = action
            const { changes, propertyId } = payload
            rotationsPropertiesAdapter.updateOne(state.properties, {
                id: propertyId,
                changes
            })
        },
        addRotationProperty(state, action) {
            const { payload } = action
            const { rotationId, propertyIds, property } = payload

            const propertyId = rotationId + '_' + randomKey(8)
            rotationsPropertiesAdapter.addOne(state.properties, {
                ...property,
                id: propertyId
            })
            rotationsAdapter.updateOne(state, {
                id: rotationId,
                changes: {
                    propertyIds: [
                        propertyId,
                        ...propertyIds
                    ]
                }
            })
        },
        removeRotationProperty(state, action) {
            const { payload } = action
            const { rotationId, propertyIds, propertyId } = payload

            rotationsPropertiesAdapter.removeOne(state.properties, propertyId)
            rotationsAdapter.updateOne(state, {
                id: rotationId,
                changes: {
                    propertyIds: [...propertyIds.filter(id => id !== propertyId)]
                }
            })
        }
    }
})
const { reducer, actions } = rotations
export const storyConfigSetRotations = (payload) => dispatch => {
    dispatch(actions.setRotations(payload))
}
export const storyConfigUpdateRotationProperty = (payload) => dispatch => {
    dispatch(actions.updateRotationProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}

export const storyConfigAddRotationProperty = (payload) => dispatch => {
    dispatch(actions.addRotationProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}

export const storyConfigRemoveRotationProperty = (payload) => dispatch => {
    dispatch(actions.removeRotationProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}


export const rotationsSelectors = rotationsAdapter.getSelectors(state => state.storyConfigState.rotations)

export const propertiesSelectors = rotationsPropertiesAdapter.getSelectors(state => state.storyConfigState.rotations.properties)

export default reducer
