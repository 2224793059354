import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

const creativeAdapter = createEntityAdapter({
    selectId: model => model.id
})

const foldersAdapter = createEntityAdapter({
    selectId: model => model.id
})

const configContent = createSlice({
    name: 'story/config/content',
    initialState: {
        storyCreative: creativeAdapter.getInitialState(),
        storyFolders: foldersAdapter.getInitialState()
    },
    reducers: {
        setCreative(state, action) {
            const {payload} = action
            creativeAdapter.setAll(state.storyCreative, payload)
        },
        setFolders(state, action) {
            const {payload} = action
            foldersAdapter.setAll(state.storyFolders, payload)
        },
    }
})
export const {actions, reducer} = configContent

export const {
    selectAll: selectAllContentCreative,
    selectById: selectContentCreativeById,
    selectEntities: selectContentCreativeEntities,
    selectIds: selectContentCreativeIds,
    selectTotal: selectContentCreativeValues
} = creativeAdapter.getSelectors(state => state.storyConfigState.content.storyCreative)

export const {
    selectAll: selectAllContentFolders,
    selectById: selectContentFoldersById,
    selectEntities: selectContentFoldersEntities,
    selectIds: selectContentFoldersIds,
    selectTotal: selectContentFoldersValues
} = foldersAdapter.getSelectors(state => state.storyConfigState.content.storyFolders)
