import React from "react"
import { Menu } from "antd"
import styled from "styled-components"
import { useAuth } from "../../hooks/use-auth"

const HeaderMenu = () => {
  const { logout, user } = useAuth()
  return (
    <Menu
      mode="horizontal"
      triggerSubMenuAction="click"
      theme="dark"
      selectedKeys={["/"]}
    >
      <Menu.SubMenu
        key="sub1"
        style={{ float: "right" }}
        title={user.name && user.name.toUpperCase()}
      >
        <Menu.ItemGroup>
          <Menu.Item onClick={logout}>Выйти</Menu.Item>
        </Menu.ItemGroup>
      </Menu.SubMenu>
    </Menu>
  )
}

const MenuItem = styled(Menu.Item)`
  &.ant-menu-item-selected a:after {
    background: #8DD4FC;
    content: "";
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 15px;
    height: 2px;
  }
`

const MenuLogo = styled(Menu.Item)`
  &&& {
    padding: 0;
    margin-right: 1rem;
  }
  img {
    width: 112px;
  }
`

export default HeaderMenu
