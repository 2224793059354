import React from 'react';
import ReactDOM from 'react-dom';
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {ConfigProvider} from "antd";
import 'antd/dist/antd.less';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ProvideAuth} from "./hooks/use-auth";
import {setAuthToken} from "./api/authAPI";
import './index.css';

import ruRu from 'antd/lib/locale/ru_RU'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const token = localStorage.getItem('token')
if (token) setAuthToken(token)

ReactDOM.render(
    <BrowserRouter>
        <ConfigProvider locale={ruRu}>
            <ProvideAuth>
                <DndProvider backend={HTML5Backend}>
                    <App/>
                </DndProvider>
            </ProvideAuth>
        </ConfigProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
