import React, {useEffect, useMemo, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Button, Space} from "antd";
import {FormOutlined, ReloadOutlined} from "@ant-design/icons";
import {TEMPLATE_EDITOR_ID} from "../../../utils/consts";


const StoryImage = () => {
    const {storyExtra} = useSelector(state => ({
        storyExtra: state.storyConfigState.extra,
    }), shallowEqual)
    const [iframeSrc, setIframeSrc] = useState(null)
    const [reloadKey, setReloadKey] = useState(0)
    const reload = () => setReloadKey(key => ++key)

    const toTemplateEditor = () => {
        window.open(`template-editor/template-edit/${storyExtra.id}`)
    }

    const isTemplateEditor = useMemo(
        () => storyExtra?.template?.id === TEMPLATE_EDITOR_ID,
        [storyExtra]
    )

    const isTemplateEditorCreated = useMemo(
        () => storyExtra?.template?.id === TEMPLATE_EDITOR_ID ?
            storyExtra?.layout !== null
            :
            true,
        [storyExtra]
    )

    useEffect(() => {
        const {guid} = storyExtra
        const src = `https://mcreams.dron.digital/api/v1/creams/story/preview/${guid}/index.html?noinit=1`
        fetch(src)
            .then((data) => {
                if (data.ok) setIframeSrc(src)
            })
    }, [storyExtra])

    return (
        <Space size={15} direction="vertical" style={{width: '100%'}}>
            <div style={{height: '180px', width: '100%'}}>
                {
                    iframeSrc && (
                        isTemplateEditorCreated ? (
                            <iframe key={reloadKey} src={iframeSrc}
                                    style={{border: 'none', width: '100%', height: '100%'}}
                            />
                        )
                        :
                        <div>
                            Креатив ещё не создан
                        </div>
                    )
                }
            </div>
            <Space style={{width: '100%', justifyContent: 'center'}}>
                <Button size="middle" icon={<ReloadOutlined/>} onClick={reload}/>
                {
                    isTemplateEditor && (
                        <Button size="middle" icon={<FormOutlined/>} onClick={toTemplateEditor}/>
                    )
                }
            </Space>
        </Space>
    )
}


export default StoryImage
