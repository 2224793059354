import React, {useEffect} from "react";
import {Layout, message} from "antd";
import HeaderMenu from "./HeaderMenu";
import {errorHandlerInterceptor, removeRequestInterceptor} from "../../api/instance";

const {Header} = Layout

const WithAuthLayout = ({children}) => {
    useEffect(()=>{
        const interceptor = errorHandlerInterceptor(err => message.error(err?.response?.data?.msg))

        return () => {
            removeRequestInterceptor(interceptor)
        }
    },[])

    return (
        <Layout>
            <Header>
                <HeaderMenu/>
            </Header>
            {children}
        </Layout>
    )
}
export default WithAuthLayout
