import instance from "./instance";

export const storyAPI = {
    foldersTree: () => instance.post('api/v1/creams/story/list/1'),
    createFolder: (folderId, {name, type}) => instance.post(`api/v1/creams/story/create/${folderId || 1}`, {name, type: 'folder'}),
    renameFolder: (folderId, {name, type}) => instance.post(`api/v1/creams/story/rename/${folderId}`, {name, type}),
    deleteFolder: (folderId, {type}) => instance.post(`api/v1/creams/story/delete/${folderId}`, {type}),
    getStory: (storyId) => instance.post(`api/v1/creams/story/${storyId}`),
    createStory: (folderId, {name,template_id}) => instance.post(`api/v1/creams/story/create/${folderId}`, {name, template_id, type: 'story'}),
    copyStory: storyId => instance.post(`api/v1/creams/story/copy/${storyId}`),
    updateStory: (storyId, data) =>  instance.post(`api/v1/creams/story/write/${storyId}`, data),
    deleteStory: (storyId) => instance.post(`api/v1/creams/story/delete/${storyId}`, {type: 'story'}),
    templates: () => instance.post(`api/v1/creams/story/template/list`),
}
