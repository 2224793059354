import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {randomKey} from "../../../utils/common";
import {storyConfigActions} from "../story";

const propertiesAdapter = createEntityAdapter({
    selectId: model => model.id,
    sortComparer: (a, b) => b.addIndex - a.addIndex
})

const valuesAdapter = createEntityAdapter({
    selectId: model => model.id
})

const configProperties = createSlice({
    name: 'story/config/properties',
    initialState: {
        ...propertiesAdapter.getInitialState(),
        values: valuesAdapter.getInitialState(),
        lastAdded: 0,
    },
    reducers: {
        setProperties(state, action) {
            const {payload} = action
            let propertiesValues = []
            const properties = payload.map(({values, ...property}) => {
                const propertyId = randomKey(8)
                const valuesIds =  values.map((v, index) => {
                    const valueId = propertyId + '_' + index
                    propertiesValues.push({id: valueId, ...v})
                    return valueId
                })
                return ({id: propertyId, valuesIds, addIndex: 0, ...property})
            })
            propertiesAdapter.setAll(state, properties)
            valuesAdapter.setAll(state.values, propertiesValues)
            state.lastAdded = 0
        },
        updateProperty(state, action) {
           const {payload} = action
           const {changes, propertyId} = payload
            propertiesAdapter.updateOne(state, {
                id: propertyId,
                changes
            })
        },
        addProperty(state, action) {
            const {payload} = action
            const {property} = payload
            const propertyId = randomKey(8)
            const values = property.valuesIds.map((id, index) => {
                return ({
                    ...state.values.entities[id],
                    id: propertyId + '_' + index
                })
            })
            valuesAdapter.addMany(state.values, values)
            propertiesAdapter.addOne(state, {
                ...property,
                id: propertyId,
                valuesIds: values.map(v => v.id),
                addIndex: state.lastAdded + 1
            })
            state.lastAdded = state.lastAdded + 1
        },
        removeProperty(state, action) {
            const {payload} = action
            const {propertyId, valuesIds} = payload
            valuesAdapter.removeMany(state.values, valuesIds)
            propertiesAdapter.removeOne(state, propertyId)
        },
        updatePropertyValue(state, action) {
            const {payload} = action
            const {valueId, changes} = payload
            valuesAdapter.updateOne(state.values, {
                id: valueId,
                changes
            })
        }
    }
})

const {actions, reducer} = configProperties

export const storyConfigSetProperties = (payload) => dispatch => {
    dispatch(actions.setProperties(payload))
}

export const storyConfigUpdateProperty = (payload) => dispatch => {
    dispatch(actions.updateProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}
export const storyConfigAddProperty = (payload) => dispatch => {
    dispatch(actions.addProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}
export const storyConfigRemoveProperty = (payload) => dispatch => {
    dispatch(actions.removeProperty(payload))
    dispatch(storyConfigActions.setEdited(true))
}
export const storyConfigUpdatePropertyValue = (payload) => dispatch => {
    dispatch(actions.updatePropertyValue(payload))
    dispatch(storyConfigActions.setEdited(true))
}

export const propertiesSelectors = propertiesAdapter.getSelectors(state => state.storyConfigState.properties)
export const valuesSelectors = valuesAdapter.getSelectors(state => state.storyConfigState.properties.values)

export default reducer
