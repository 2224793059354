import React, {useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Row, Col, Space, InputNumber, Input, DatePicker, Divider, List} from "antd";
import Switcher from '../../shared/Switcher'
import {DATE_FORMAT, PROPERTY_TYPES} from "../../../utils/consts";
import {TextStyled} from "../../styled/Typography";
import TimeTarget from "../../shared/TimeTarget";
import {MinusButton, PlusButton} from "../../shared/Buttons";
import {
    propertiesSelectors, valuesSelectors,
    storyConfigAddProperty, storyConfigRemoveProperty,
    storyConfigUpdateProperty,
    storyConfigUpdatePropertyValue,
} from '../../../redux/modules/story-config/story-config-properties'
import moment from "moment";

const StoryPropertiesList = () => {
    const propertiesIds = useSelector(state => propertiesSelectors.selectIds(state), shallowEqual)

    return (
        <List
            itemLayout="vertical"
            bordered={false}
            split={false}
            dataSource={propertiesIds}
            renderItem={id => <FieldItem key={id} propertyId={id} propertiesIds={propertiesIds}/>}
        />
    );
}

const FieldItem = ({propertyId, propertiesIds}) => {
    const property = useSelector(state => propertiesSelectors.selectById(state, propertyId), shallowEqual)
    const dispatch = useDispatch()

    const {target, valuesIds} = property

    const isLast = propertiesIds[propertiesIds.length - 1] === propertyId

    const addProperty = () => {
        dispatch(storyConfigAddProperty({property}))
    }

    const canRemoveProperty = propertiesIds.length > 1 && target !== null

    const removeProperty = () => {
        dispatch(storyConfigRemoveProperty({propertyId, valuesIds}))
    }

    const applyTarget = target => {
        dispatch(storyConfigUpdateProperty({propertyId, changes: {target}}))
    }

    return (
        <List.Item>
            <div style={{width: '100%', padding: '0 10px'}}>
                <Row gutter={20}>
                    <Col span={15}>
                        <Row>
                            <Col span={12}>НАСТРОЙКИ СЮЖЕТА</Col>
                            <Col span={12}>ЗНАЧЕНИЕ</Col>
                        </Row>
                        {
                            useMemo(()=> (
                                <List
                                    itemLayout="vertical"
                                    bordered={false}
                                    split={false}
                                    dataSource={valuesIds}
                                    renderItem={id => <PropertyValue valueId={id}/>}
                                />
                            ), [valuesIds])
                        }
                    </Col>
                    <Col span={9}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Space direction="vertical" align="end">
                                <TimeTarget initialTarget={target} apply={applyTarget}/>
                                <div style={{padding: '0 7px'}}>
                                    <TextStyled style={{color: '#0D5CAA'}}>
                                        {target === null ? 'БЕЗ ОГРАНИЧЕНИЙ' : 'ТАРГЕТИНГ НАСТРОЕН'}
                                    </TextStyled>
                                </div>
                            </Space>
                            <PropertiesActions add={addProperty} remove={removeProperty} canRemove={canRemoveProperty}/>
                        </div>
                    </Col>
                </Row>
            </div>
            {!isLast && <Divider/>}
        </List.Item>
    )
}

const PropertiesActions = ({add, remove, canRemove}) => (
    <Space size={10}>
        <PlusButton onClick={add} />
        <MinusButton disabled={!canRemove} onClick={remove} />
    </Space>
)


const PropertyValue = ({valueId}) => {
    const propertyValue = useSelector(state => valuesSelectors.selectById(state, valueId), shallowEqual)
    const dispatch = useDispatch()

    const update = (value) => {
        dispatch(storyConfigUpdatePropertyValue({
            valueId, changes: {value}
        }))
    }
    if (!propertyValue) return <List.Item />
    const {name, type, value} = propertyValue
    return (
        <List.Item>
            <Row>
                <Col span={12}>
                    <TextStyled $blockInline>{name}</TextStyled>
                </Col>
                <Col span={12}>
                    <Switcher
                        currentKey={type}
                        switchComponents={[
                            {
                                switchKey: PROPERTY_TYPES.INT,
                                component: (
                                    <FieldWrapper initialValue={value} submit={update}>
                                        {(value, onChange, onSubmit) => (
                                            <InputNumber formatter={value => Math.trunc(value)} size="small" value={value} onChange={onChange} onBlur={()=>onSubmit()}/>
                                        )}
                                    </FieldWrapper>
                                )
                            },
                            {
                                switchKey: PROPERTY_TYPES.DATE,
                                component: (
                                    <FieldWrapper initialValue={moment(value,DATE_FORMAT)} submit={update}>
                                        {(value, onChange, onSubmit) => (
                                            <DatePicker size="small" allowClear={false}
                                                        value={value} onChange={onChange}
                                                        onSelect={value => onSubmit(moment(value).format(DATE_FORMAT))} format={DATE_FORMAT}
                                            />
                                        )}
                                    </FieldWrapper>
                                )
                            },
                            {
                                switchKey: PROPERTY_TYPES.STRING,
                                component: (
                                    <FieldWrapper initialValue={value} submit={update}>
                                        {(value, onChange, onSubmit) => (
                                            <Input size="small" style={{width: 'unset'}} value={value} onChange={({target})=>onChange(target.value)} onBlur={()=>onSubmit()}/>
                                        )}
                                    </FieldWrapper>
                                )
                            },
                            {
                                switchKey: PROPERTY_TYPES.FLOAT,
                                component: (
                                    <FieldWrapper initialValue={value} submit={update}>
                                        {(value, onChange, onSubmit) => (
                                            <InputNumber size="small"
                                                         value={value.toString().replace(',','.')}
                                                         onChange={onChange} onBlur={()=>onSubmit()}/>
                                        )}
                                    </FieldWrapper>
                                )
                            }
                        ]}
                        defaultComponent={(
                            <FieldWrapper initialValue={value} submit={update}>
                                {(value, onChange, onSubmit) => (
                                    <Input size="small" style={{width: 'unset'}} value={value} onChange={onChange} onBlur={()=>onSubmit()}/>
                                )}
                            </FieldWrapper>
                        )}
                    />
                </Col>
            </Row>
        </List.Item>
    )
}

const FieldWrapper = ({initialValue, submit, children}) => {
    const [value, setValue] = useState(initialValue)

    const onSubmit = submitValue => submit(submitValue || value)
    const onChange = value => {
        setValue(value)
    }
    return children(value, onChange, onSubmit)
}

export default StoryPropertiesList
