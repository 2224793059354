import {FOLDERS_ROOT_TREE_KEY} from "../../utils/consts";
import React, {useCallback, useState} from "react";
import {Button, Modal, Space, Tree} from "antd";
import {FolderTitle} from "./Tree";
import {shallowEqual, useSelector} from "react-redux";

const CreativeSelect = ({creativeTree, selectedFolders, selectedCreative, onOk, onCancel}) => {
    const rootDirectory = [{
        title: 'БИБЛИОТЕКА',
        key: FOLDERS_ROOT_TREE_KEY,
        children: creativeTree,
    }]
    const [selected, select] = useState([...selectedFolders])

    const onStorySelect = (keys, info) => {
        select(keys)
    }
    const submit = useCallback(()=> {
        onOk(selected)
    }, [selected])
    return (
        <div>
            <div style={{maxHeight: '300px', overflow: 'auto'}}>
                <Tree
                    defaultExpandAll
                    multiple={true}
                    selectedKeys={selected}
                    className="directory-tree"
                    showIcon={true}
                    icon={''}
                    style={{width: 'max-content', minWidth: '100%'}}
                    titleRender={({key, title, ...data}) => (
                        <FolderTitle id={key} title={title} nestedNumber={data?.number_creatives} type={data.type}/>
                    )}
                    treeData={rootDirectory}
                    onSelect={onStorySelect}
                />
            </div>
            <Space style={{float: 'right', marginTop: '10px'}}>
                <Button onClick={onCancel}>ЗАКРЫТЬ</Button>
                <Button type="primary" onClick={submit}>ПРИМЕНИТЬ</Button>
            </Space>
        </div>
    )
}

const AddCreative = ({selectedFolders, selectedCreative, apply}) => {
    const {creativeTree} = useSelector(state => ({
        creativeTree: state.creativeTreeState.creativeTree,
    }), shallowEqual)
    const modalOpen = useCallback(()=> {
        const onOk = ids => {
            apply(ids)
            modal.destroy()
        }
        const onCancel = () => modal.destroy()
        const modal = Modal.confirm({
            width: '360px',
            content: <CreativeSelect
                onOk={onOk} onCancel={onCancel}
                selectedFolders={selectedFolders} selectedCreative={selectedCreative}
                creativeTree={creativeTree}
            />,
            title: '',
            okText: 'ПРИМЕНИТЬ',
            className: 'target-modal',
            cancelButtonProps: {disabled: true, style: {display: 'none'}},
            okButtonProps: {disabled: true, style: {display: 'none'}},
            cancelText: 'ЗАКРЫТЬ',
            centered: true,
            maskClosable: true,
            icon: '',
        })
    }, [selectedFolders, selectedCreative, creativeTree])
    return (
        <Button type="primary" onClick={modalOpen}>ДОБАВИТЬ КРЕАТИВ</Button>
    )
}

export default AddCreative
