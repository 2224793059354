import {storyAPI} from "../../api/storyAPI";
import {combineReducers, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setCurrentFolderId, setCurrentView} from "./manager";
import {VIEW_TABS} from "../../utils/consts";
import rotations, {storyConfigSetRotations} from "./story-config/story-config-rotations";
import {reducer as content, actions as contentActions} from "./story-config/story-config-content";
import properties, {storyConfigSetProperties} from "./story-config/story-config-properties";
import {reducer as extra, actions as extraActions} from "./story-config/story-config-extra";
import reduceReducers from "reduce-reducers";
import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import {configListActions} from "./story-config/story-config-persisted-list";

const initialStoryConfigState = {
    requestId: null,
    isStoryConfigLoading: true,
    isEdited: false,
}

const fetchStoryConfig = createAsyncThunk(
    'story/config/fetchStoryConfig',
    async (storyId, {dispatch, getState}) => {
        dispatch(originalActions.setRequestId(storyId))
        dispatch(originalActions.setLoading(true))

        const responseConfig = await storyAPI.getStory(storyId)
            .then(({data}) => data?.data || null)
            .catch(error => {
                console.error(error)
                dispatch(originalActions.setRejected())
                dispatch(setCurrentFolderId(null))
                dispatch(setCurrentView(VIEW_TABS.INITIAL_TAB))
            })
        if (responseConfig === null) return

        const state = getState()
        const currentConfig = state.storyConfigState
        const configList = state.storyConfigList

        if (storyId !== currentConfig.requestId) return

        if (currentConfig.isEdited) {
            const unSaved = dispatch(storyConfigActions.getNormalized())
            dispatch(configListActions.addOne(unSaved))
        }

        if (responseConfig.guid === currentConfig.extra.guid) {
            dispatch(originalActions.setEdited(false))
            dispatch(originalActions.setLoading(false))
            return
        }

        const existInConfigList = configList.ids.includes(responseConfig.guid)
        if (existInConfigList) {
            dispatch(originalActions.setEdited(true))
            dispatch(setConfigEntities(configList.entities[responseConfig.guid]))
        } else {
            dispatch(originalActions.setEdited(false))
            dispatch(setConfigEntities(responseConfig))
        }
    }
)

const setConfigEntities = (config) => dispatch => {
    dispatch(extraActions.setExtra(config))
    dispatch(contentActions.setFolders(config.story_content?.folders || []))
    dispatch(contentActions.setCreative(config.story_content?.creatives || []))
    dispatch(storyConfigSetRotations(config.story?.rotations || []))
    dispatch(storyConfigSetProperties(config.story?.properties || []))
    dispatch(originalActions.setLoading(false))
}

const {reducer: originalReducer, actions: originalActions} = createSlice({
    name: 'story/config',
    initialState: initialStoryConfigState,
    reducers: {
        setRequestId(state, action) {
            state.requestId = action.payload
        },
        setLoading(state, action) {
            state.isStoryConfigLoading = action.payload
        },
        setRejected(state) {
            state.requestId = null
            state.isStoryConfigLoading = false
        },
        setEdited(state, action) {
            state.isEdited = action.payload
        }
    }
})

const nestedEntitiesReducer = combineReducers(({
    requestId: (s = null) => s,
    isStoryConfigLoading: (s = null) => s,
    isEdited: (s = null) => s,
    extra,
    rotations,
    content,
    properties
}))

const storyConfigPersistConfig = {
    key: 'story-config',
    storage: storage,
    blacklist: ['requestId', 'isStoryConfigLoading']
}

export const storyConfigReducer = persistReducer(storyConfigPersistConfig, reduceReducers(originalReducer, nestedEntitiesReducer))

export const storyConfigActions = {
    ...originalActions,
    fetchStoryConfig,
    getNormalized: () => (dispatch, getState) => {
        const storyConfigState = getState().storyConfigState
        if (!storyConfigState) return {}
        const {rotations, properties, content, extra} = storyConfigState

        return ({
            ...extra,
            story: {
                rotations: rotations.ids.map(id => {
                    const {propertyIds, id: guid, ...rotation} = rotations.entities[id]
                    return ({
                        guid,
                        ...rotation,
                        properties: propertyIds.map(pid => {
                            const {id: _, ...property} = rotations.properties.entities[pid]
                            return property
                        })
                    })
                }),
                properties: properties.ids.map(id => {
                    const {target, valuesIds} = properties.entities[id]
                    return ({
                        target,
                        values: valuesIds.map(vid => {
                            const {id: _, ...value} = properties.values.entities[vid]
                            return value
                        })
                    })
                })
            },
            story_content: {
                creatives: content.storyCreative.ids.map(id => content.storyCreative.entities[id]),
                folders: content.storyFolders.ids.map(id => content.storyFolders.entities[id])
            },
        })
    }
}
