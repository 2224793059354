import React from "react";
import Space from "antd/es/space";
import {Button, Modal} from "antd";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {storyAPI} from "../../../api/storyAPI";
import {storyConfigActions} from "../../../redux/modules/story";
import {fetchStoryTree} from "../../../redux/modules/story-tree"
import {setCurrentFolder, setCurrentView} from "../../../redux/modules/manager";
import {FOLDERS_ROOT_TREE_KEY, VIEW_TABS} from "../../../utils/consts";
import {configListActions} from "../../../redux/modules/story-config/story-config-persisted-list";



const StoryHeader = () => {
    const {currentFolderId} = useSelector(state => ({
        currentFolderId: state.manager.currentFolder.id,
    }), shallowEqual)

    const dispatch = useDispatch()
    const updateStory = async () => {
        const data = dispatch(storyConfigActions.getNormalized())
        await storyAPI.updateStory(currentFolderId,data)
        dispatch(configListActions.removeOne(data.guid))
        dispatch(storyConfigActions.setEdited(false))
        dispatch(storyConfigActions.fetchStoryConfig(currentFolderId))
    }
    const copyStory = async () => {
        const id = await storyAPI.copyStory(currentFolderId)
            .then(({data}) => data?.data?.story_id)
        await dispatch(fetchStoryTree())
        if (id) {
            dispatch(setCurrentFolder({id, type: 'story'}))
            dispatch(setCurrentView(VIEW_TABS.STORY_TAB))
        } else {
            dispatch(setCurrentFolder({id: FOLDERS_ROOT_TREE_KEY, type: 'folder'}))
            dispatch(setCurrentView(VIEW_TABS.STORY_TAB))
        }
    }
    const deleteStory = () => {
        const handleDelete = async () => {
            const data = dispatch(storyConfigActions.getNormalized())
            dispatch(configListActions.removeOne(data.guid))
            dispatch(storyConfigActions.setEdited(false))
            await storyAPI.deleteStory(currentFolderId)
            dispatch(fetchStoryTree())
            dispatch(setCurrentFolder({id: FOLDERS_ROOT_TREE_KEY, type: 'folder'}))
            dispatch(setCurrentView(VIEW_TABS.STORY_TAB))
        }
        Modal.confirm({
            title: 'Удалить сюжет?',
            content: 'Все сохранения будут удалены',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: handleDelete,
        })
    }

    return (
        <div style={{width: '100%', height: '70px', padding: '20px 40px', boxShadow: '0px 4px 20px rgba(189, 198, 209, 0.2)'}}>
            <Space size={10}>
                <Button onClick={copyStory}>КОПИРОВАТЬ</Button>
                <Button type="danger" onClick={deleteStory}>УДАЛИТЬ</Button>
                <Button type="primary" onClick={updateStory}>СОХРАНИТЬ</Button>
            </Space>
        </div>
    );
}

export default StoryHeader
