import React, {useCallback, useMemo, useState} from "react";
import {Button, Checkbox, DatePicker, Divider, Modal, Space, Typography} from "antd";
import {TextStyled} from "../styled/Typography";
import {ClockCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import styled from "styled-components";
import './TimeTarget.scss'
import * as _ from 'lodash'
import {TIME_HOURS, TIME_DAYS, DATE_FORMAT} from '../../utils/consts'
import moment from "moment";

const TimeTarget = ({apply, initialTarget}) => {
    const Title = () => (
        <>
            <Typography.Title level={4} style={{marginBottom: '0'}}>Временной таргетинг рекламной
                кампании</Typography.Title>
            <TextStyled $size={12}>Креативы РК будут транслироваться только в эти дни, и только в указанное
                время</TextStyled>
        </>
    )
    const modalOpen = useCallback(() => {
        const onOk = ({times, start, end}) => {
            const isTimesFull = times.every((hours) => hours.every(h => h))
            const timesFormatted = _.chain(times)
                .map(hours => {
                    return _.chain(hours)
                        .map((hour, hourIndex) => hour ? Number(hourIndex) + 1 : null)
                        .filter(time => time !== null)
                        .value()
                })
                .reduce((acc, hours, dayIndex) => {
                    const day = dayIndex + 1
                    return hours.length > 0 ?
                        ({...acc, [day === 7 ? 0 : day]: hours})
                        :
                        ({...acc})

                }, {})
                .value()
            const target = isTimesFull ?
                null
                :
                ({
                    start: (moment.isMoment(start) && moment(start).format(DATE_FORMAT)) || null,
                    end: (moment.isMoment(end) && moment(end).format(DATE_FORMAT)) || null,
                    timeset: timesFormatted
                })
            apply(target)
            modal.destroy()
        }
        const onCancel = () => modal.destroy()

        const modal = Modal.confirm({
            width: '760px',
            content: <TargetField initialTarget={initialTarget} onOk={onOk} onCancel={onCancel}/>,
            title: <Title/>,
            okText: 'ПРИМЕНИТЬ',
            className: 'target-modal',
            cancelButtonProps: {disabled: true, style: {display: 'none'}},
            okButtonProps: {disabled: true, style: {display: 'none'}},
            cancelText: 'ЗАКРЫТЬ',
            centered: true,
            closable: true,
            maskClosable: true,
            icon: '',
        })
    }, [initialTarget, apply])
    return (
        <Button onClick={modalOpen} type="text" style={{color: '#0D5CAA'}} size="small" icon={<ClockCircleOutlined/>}>
            <TextStyled>ВРЕМЕННОЙ ТАРГЕТИНГ</TextStyled>
        </Button>
    )
}

const TargetField = ({initialTarget, onOk, onCancel}) => {
    const timesObject = TIME_DAYS.map(({id}) => TIME_HOURS.map((hour) => {
        return initialTarget?.timeset ?
            !!initialTarget?.timeset?.[id]?.includes(hour)
            :
            true
    }))
    const [period, setPeriod] = useState({
        start: (initialTarget?.start && moment(initialTarget.start)) || null,
        end: (initialTarget?.end && moment(initialTarget.end)) || null
    })


    const [times, setTimes] = useState(timesObject)

    const SelectButton = useCallback(({checked, dayIndex, hourIndex}) => {
        const onClick = () => {
            setTimes(prev => {
                const copy = [...prev]
                copy[dayIndex][hourIndex] = !checked
                return copy
            })
        }
        const plusStyle = {backgroundColor: '#8DD4FC', borderRadius: 0, borderColor: '#E3E6EA', color: '#333333'}
        const minusStyle = {backgroundColor: '#FFFFFF', borderRadius: 0, borderColor: '#E3E6EA', color: '#333333'}
        const plusIcon = <PlusOutlined style={{fontSize: '12px'}}/>
        const minusIcon = <MinusOutlined style={{fontSize: '12px'}}/>
        return (
            <Button size="small" onClick={onClick}
                    style={checked ? plusStyle : minusStyle}
                    icon={checked ? plusIcon : minusIcon}
            />
        )
    }, [])

    const DaySelectButton = useCallback(({dayIndex, title, checked}) => {
        const onChange = ({target}) => {
            const value = target.checked
            setTimes(prev => {
                const copy = [...prev]
                copy[dayIndex] = copy[dayIndex].map(() => value)
                return copy
            })
        }
        return (
            <CheckBoxRightSided checked={checked} onChange={onChange}>{title}</CheckBoxRightSided>
        )
    }, [])

    const TimeSelectButton = useCallback(({hourIndex}) => {
        const checked = times.every((day, dayIndex) => times[dayIndex][hourIndex])

        const startTime = hourIndex < 10 ? `0${hourIndex}` : hourIndex
        const end = hourIndex + 1
        const endTime = end < 10 ? `0${end}` : (end === 24 && '00') || end

        const onChange = ({target}) => {
            setTimes(prev => {
                const copy = [...prev]
                TIME_DAYS.forEach((day, dayIndex) => {
                    copy[dayIndex][hourIndex] = target.checked
                })
                return copy
            })
        }

        return (
            <CheckBoxVertical checked={checked} style={{textAlign: 'center'}} onChange={onChange}>
                <div style={{paddingTop: '4px', display: 'flex', flexDirection: 'column'}}>
                    <span>{startTime}</span>
                    <span>{endTime}</span>
                </div>
            </CheckBoxVertical>
        )
    }, [times])

    const isFull = useMemo(() => {
        return times.every((hours) => hours.every(h => h))
    }, [times])

    const submit = useCallback(() => {
        onOk({times, start: period.start, end: period.end})
    }, [times, period])

    const presetFull = (boolean) => {
        setTimes(prev => prev.map(hours => hours.map(() => boolean)))
    }
    const presetDay = () => {
        setTimes(prev => prev.map(hours => hours.map((_, hourIndex) => hourIndex > 5 && hourIndex < 22)))
    }

    const presetNight = () => {
        setTimes(prev => prev.map(hours => hours.map((_, hourIndex) => !(hourIndex > 5 && hourIndex < 22))))
    }

    const presetPrimeTime = () => {
        setTimes(prev => prev.map(hours => hours.map((_, hourIndex) => {
            return (hourIndex > 6 && hourIndex < 11) || (hourIndex > 16 && hourIndex < 20)
        })))
    }

    return (
        <Space size={10} direction="vertical" style={{width: '100%'}}>
            <Space split="-">
                <DatePicker size="small" format={DATE_FORMAT} placeholder="С" style={{width: '120px'}}
                            value={period.start} onChange={value => setPeriod({...period,start: value})}
                />
                <DatePicker size="small" format={DATE_FORMAT} placeholder="По" style={{width: '120px'}}
                            value={period.end} onChange={value => setPeriod({...period,end: value})}
                />
            </Space>
            <Space size={0} split={<Divider type="vertical" style={{borderColor: '#0D5CAA'}}/>}>
                <PresetButton onClick={() => presetFull(!isFull)}>
                    {isFull ? 'Отменить все' : 'Выделить все'}
                </PresetButton>
                <PresetButton onClick={() => presetDay()}>
                    День
                </PresetButton>
                <PresetButton onClick={() => presetNight()}>
                    Ночь
                </PresetButton>
                <PresetButton onClick={() => presetPrimeTime()}>
                    Прайм-тайм
                </PresetButton>
            </Space>
            <table className="time-grid" style={{width: '100%'}}>
                <tbody>
                {times.map((hours, dayIndex) => {
                    const isDaySelected = hours.every(value => value)
                    const title = TIME_DAYS[dayIndex].title
                    return (
                        <tr key={dayIndex}>
                            <td>
                                <DaySelectButton dayIndex={dayIndex} title={title} checked={isDaySelected}/>
                            </td>
                            {hours.map((hour, hourIndex) => (
                                <td key={hourIndex}>
                                    <SelectButton checked={hour} dayIndex={dayIndex} hourIndex={hourIndex}/>
                                </td>
                            ))}
                        </tr>
                    )
                })}
                </tbody>
                <tfoot>
                <tr>
                    <td/>
                    {
                        TIME_HOURS.map((h, hourIndex) => (
                            <td key={hourIndex}>
                                <TimeSelectButton hourIndex={hourIndex}/>
                            </td>
                        ))
                    }
                </tr>
                </tfoot>
            </table>
            <Space style={{float: 'right', marginTop: '10px'}}>
                <Button onClick={onCancel}>ЗАКРЫТЬ</Button>
                <Button type="primary" onClick={submit}>ПРИМЕНИТЬ</Button>
            </Space>
        </Space>
    )
}
const PresetButton = ({children, ...props}) => (
    <Button size="small" type="text" style={{color: '#0D5CAA'}} {...props}>
        {children}
    </Button>
)

const CheckBoxRightSided = styled(Checkbox)`
  font-size: 14px;
  line-height: 14px;
  color: #738296;
  width: 100%;
  .ant-checkbox {
    float: right;
    margin: 0 6px;
    + span {
      padding: 0;
    }
  }
`

const CheckBoxVertical = styled(Checkbox)`
  padding-top: 8px;
  font-size: 14px;
  line-height: 15px;
  color: #738296;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-checkbox {
    & + span {
      padding: 0
    }
  }
`

export default TimeTarget
