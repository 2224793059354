import React, {useEffect, useState} from "react";
import {Form, Input, Select} from "antd";
import {storyAPI} from "../../../../api/storyAPI";

const StoryFormCreate = ({form, validate, handleSubmit}) => {
    const [templates, setTemplates] = useState([])
    const [loading, setLoading] = useState(true)
    const onSelect = (value) => value ? validate(true) : validate(false)

    useEffect(() => {
        storyAPI.templates()
            .then(({data}) => {
                const response = data?.data || []
                const templates = response.map(({id, name}) => ({value: id, label: name}))
                setTemplates(templates)
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <Form form={form} layout="vertical" name="story-create" onFinish={handleSubmit}>
            <Form.Item label="ШАБЛОН" name="template_id">
                <Select loading={loading} onSelect={onSelect} placeholder="Выбрать" options={templates}/>
            </Form.Item>
            <Form.Item label="НАЗВАНИЕ СЮЖЕТА" name="name">
                <Input placeholder="Текст"/>
            </Form.Item>
        </Form>
    )
}


export default StoryFormCreate
