import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setCurrentFolderId, setCurrentView} from "../../../redux/modules/manager";
import {fetchCreativeTree, fetchCreativeList} from "../../../redux/modules/creative";
import {Tooltip, Tree} from "antd";
import {useDrop} from "react-dnd";
import {useContextMenu} from "react-contexify";
import CreativeFoldersMenu from "./CreativeFoldersMenu";
import {CREATIVE_TREE_FOLDERS_MENU, FOLDERS_ROOT_TREE_KEY, VIEW_TABS} from "../../../utils/consts";
import {creativeAPI} from "../../../api/creativeAPI";
import {FolderTitle} from "../../shared/Tree";

const CreativeFoldersTree = ({foldersTree, loading}) => {
    const {show} = useContextMenu({id: CREATIVE_TREE_FOLDERS_MENU})

    const dispatch = useDispatch()
    const {currentFolderId, currentView} = useSelector(state => ({
        currentView: state.manager.currentView,
        currentFolderId: state.manager.currentFolder.id,
    }), shallowEqual)
    const onCreativeSelect = ([id]) => {
        if (!id) return null
        if (id === FOLDERS_ROOT_TREE_KEY) {
            dispatch(setCurrentFolderId(FOLDERS_ROOT_TREE_KEY))
            dispatch(setCurrentView(VIEW_TABS.CREATIVE_TAB))
        } else {
            dispatch(setCurrentFolderId(id))
            dispatch(setCurrentView(VIEW_TABS.CREATIVE_TAB))
            dispatch(fetchCreativeList(id))
        }
    }

    const moveCreativeToFolder = (creativeId, creativeData, folderId) => {
        creativeAPI.updateCreative(creativeId, {
            ...creativeData,
            folder_id: folderId
        })
            .then(() => {
                dispatch(setCurrentFolderId(folderId))
                dispatch(fetchCreativeList(folderId))
                dispatch(fetchCreativeTree())
            })
    }

    const onRightClick = ({event, node}) => {
        if (node.key !== FOLDERS_ROOT_TREE_KEY) show(event, {
            props: {
                folderId: node.key,
                name: node.title,
            }
        })
    }

    const rootDirectory = [{
        title: 'БИБЛИОТЕКА',
        key: FOLDERS_ROOT_TREE_KEY,
        children: foldersTree,
    }]

    useEffect(() => {
        if (currentView === VIEW_TABS.CREATIVE_TAB) onCreativeSelect([currentFolderId])
    }, [])
    if (loading) return <div/>
    return (
        <div style={{overflowX: 'auto'}}>
            <Tree
                defaultExpandAll
                selectedKeys={currentView === VIEW_TABS.CREATIVE_TAB ? [currentFolderId] : null}
                className="directory-tree"
                showIcon={true}
                icon={''}
                style={{width: 'max-content', minWidth: '100%'}}
                titleRender={({key, title, ...data}) => (
                    <CreativeFolderTitle id={key} title={title} data={data} moveCreativeToFolder={moveCreativeToFolder}/>
                )}
                treeData={rootDirectory}
                onSelect={onCreativeSelect}
                onRightClick={onRightClick}
            />
            <CreativeFoldersMenu menuID={CREATIVE_TREE_FOLDERS_MENU} onCreativeSelect={onCreativeSelect}/>
        </div>
    )
}

const CreativeFolderTitle = ({id, title, data, moveCreativeToFolder}) => {
    const [collectedProps, drop] = useDrop({
        accept: "CREATIVE",
        canDrop: () => id !== FOLDERS_ROOT_TREE_KEY,
        drop: ({id: creativeId ,...creativeData}, monitor) => {
            moveCreativeToFolder(creativeId, creativeData, id)
        }
    })
    return (
        <div ref={drop}>
            <FolderTitle
                title={title}
                nestedNumber={data?.number_creatives}
                type="folder" id={id}
            />
        </div>
    )
}

export default CreativeFoldersTree
