import styled from "styled-components";
import {Typography} from "antd";



export const TextStyled = styled(Typography.Text)`
  font-size: 12px;
  line-height: 14px;
  ${({ $size }) => $size && `font-size: ${$size}px; line-height: ${$size + 2}px;`};
  ${({ $blockInline }) => $blockInline && 'display: inline-block;'}
`

