import InboxOutlined from "@ant-design/icons/es/icons/InboxOutlined";
import React, {useMemo, useState} from "react";
import {Space, Upload} from "antd";
import {creativeAPI} from "../../../api/creativeAPI";
import {fetchCreativeList} from "../../../redux/modules/creative";
import {useDispatch} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";
import debounce from 'lodash/debounce'

const { Dragger } = Upload

const CreativeUploader = ({ folderId }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const updateDebounced = debounce(() => dispatch(fetchCreativeList(folderId)), 500)

    const uploadProps = useMemo(() => ({
        name: 'content_file',
        multiple: true,
        customRequest: async ({ file }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append('content_file', file)
            await creativeAPI.addCreative(folderId, formData)
                .finally(() => {
                    setLoading(false)
                })
            updateDebounced()
        }
    }), [folderId])

    return (
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Dragger {...uploadProps} showUploadList={false}>
                <p className="ant-upload-drag-icon">
                    {
                        loading ? <LoadingOutlined/> : <InboxOutlined/>
                    }
                </p>
                <p className="ant-upload-text">
                    {
                        loading ? 'ЗАГРУЗКА КРЕАТИВА...' : 'ЗАГРУЗИТЬ КРЕАТИВ'
                    }
                </p>
            </Dragger>
        </Space>
    )
}

export default CreativeUploader
