import React, {useCallback, useEffect, useMemo, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Modal, Form, Space} from "antd";
import {creativeAPI} from "../../../api/creativeAPI";
import CreativeTable from "../Creative/tables/CreativeTable";
import CreativeForm from "../Creative/forms/CreativeForm";
import CreativeFilter from "../Creative/forms/CreativeFilter";
import {fetchCreativeList} from "../../../redux/modules/creative";
import CreativeUploader from "../Creative/CreativeUploader";
import {FOLDERS_ROOT_TREE_KEY} from "../../../utils/consts";

const CreativeTab = () => {
    const {isCreativeListLoading, creativeList, currentFolderId} = useSelector(state => ({
        isCreativeListLoading: state.creativeTreeState.isCreativeListLoading,
        creativeList: state.creativeListState.creativeList,
        currentFolderId: state.manager.currentFolder.id
    }), shallowEqual)
    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const [categories, setCategories] = useState({list: [], isLoading: true})

    const showCreativeModal = useCallback((creativeId, categoryId) => {
        const submit = async fields => {
            await creativeAPI.creativeProperties(creativeId, fields)
            dispatch(fetchCreativeList(currentFolderId))
        }
        Modal.confirm({
            content: (
                <CreativeForm form={form} handleSubmit={submit}
                              categories={categories.list}
                />
            ),
            okText: 'ПРИМЕНИТЬ',
            cancelText: 'ОТМЕНИТЬ',
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: form.submit
        })
        form.setFieldsValue({
            category_id: categoryId,
            name: null,
            value: null,
            value_type: 'string',
        })
    }, [form, categories, currentFolderId])

    useEffect(() => {
        creativeAPI.category()
            .then(({data}) => data.data)
            .then(categories => setCategories({list: categories, isLoading: false}))
            .catch(() => setCategories({list: [], isLoading: false}))
    }, [])

    const data = creativeList.map(
        ({id, ...rest}) => ({
            id,
            key: id,
            columnData: {id, ...rest}
        })
    )
    if (currentFolderId === FOLDERS_ROOT_TREE_KEY) return <div />
    return (
        <Space size={20} direction="vertical" style={{width: '100%', padding: '20px 15px'}}>
            {/*<CreativeFilter/>*/}
            <CreativeUploader folderId={currentFolderId}/>
            <CreativeTable
                loading={isCreativeListLoading || categories.isLoading}
                data={data} folderId={currentFolderId} categories={categories.list}
                addProperty={showCreativeModal}
            />
        </Space>
    );
}

export default CreativeTab
