import {Form, Input} from "antd";
import React from "react";


const FolderForm = ({form, handleSubmit}) => (
    <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="name"><Input/></Form.Item>
    </Form>
)

export default FolderForm
