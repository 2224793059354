export const DATE_FORMAT = 'YYYY-MM-DD'

export const PROPERTY_TYPES = {
    INT: 'int',
    FLOAT: 'float',
    STRING: 'string',
    DATE: 'date'
}

export const VIEW_TABS = {
    INITIAL_TAB: 'INITIAL_TAB',
    CREATIVE_TAB: 'CREATIVE_TAB',
    STORY_TAB: 'STORY_TAB'
}

export const FOLDERS_ROOT_TREE_KEY = 'FOLDERS_ROOT'
export const CREATIVE_TREE_FOLDERS_MENU = 'CREATIVE_TREE_FOLDERS_MENU'
export const STORY_TREE_FOLDERS_MENU = 'STORY_TREE_FOLDERS_MENU'
export const STORY_TREE_ROTATION_MENU = 'STORY_TREE_ROTATION_MENU'

export const TIME_DAYS = [
    {
        id: 1,
        code: 'Mon',
        title: 'пн',
    },
    {
        id: 2,
        code: 'Tue',
        title: 'вт',
    },
    {
        id: 3,
        code: 'Wed',
        title: 'ср',
    },
    {
        id: 4,
        code: 'Thu',
        title: 'чт',
    },
    {
        id: 5,
        code: 'Fri',
        title: 'пт',
    },
    {
        id: 6,
        code: 'Sat',
        title: 'сб',
    },
    {
        id: 0,
        code: 'Sun',
        title: 'вс',
    },
]
export const TIME_HOURS = [...Array.from({length: 24}, (_, i) => i + 1)]

export const TEMPLATE_EDITOR_ID = 111
