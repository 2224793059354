import {VIEW_TABS} from "../../utils/consts";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    currentView: VIEW_TABS.INITIAL_TAB,
    currentFolder: {
        id: null,
        type: null,
        storyUID: null,
    },
    splitPane: {
        position: '85%'
    }
}

const manager = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        resetManagerState: () => initialState,
        setCurrentView(state, action) {
            state.currentView = action.payload
        },
        setCurrentFolder(state, action) {
            state.currentFolder = action.payload
        },
        setCurrentFolderId(state, action) {
            state.currentFolder.id = action.payload
        },
        setSplitPanePosition(state, action) {
            state.splitPane.position = action.payload
        }
    }
})

const {actions, reducer} = manager

export const {
    setCurrentView, setCurrentFolder, setCurrentFolderId,
    setSplitPanePosition, resetManagerState
} = actions

export default reducer
