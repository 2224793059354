import React from "react";
import Switcher from "../shared/Switcher";
import {shallowEqual, useSelector} from "react-redux";
import {VIEW_TABS} from "../../utils/consts";
import StoryTab from "./tabs/StoryTab";
import CreativeTab from "./tabs/CreativeTab";

const ManagerView = () => {
    const { currentView } = useSelector(state => ({
        currentView: state.manager.currentView,
    }), shallowEqual)
    return (
        <Switcher
            switchComponents={[
                {
                    switchKey: VIEW_TABS.CREATIVE_TAB,
                    component: <CreativeTab/>
                },
                {
                    switchKey: VIEW_TABS.STORY_TAB,
                    component: <StoryTab/>
                },
                {
                    switchKey: VIEW_TABS.INITIAL_TAB,
                    component: <div/>
                }
            ]}
            currentKey={currentView}
        />
    )
}

export default ManagerView
