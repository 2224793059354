import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Col, Row, Space} from "antd";
import {FOLDERS_ROOT_TREE_KEY} from "../../../utils/consts";
import StoryRotationList from "../Story/StoryRotationList";
import StoryHeader from "../Story/StoryHeader";
import StoryImage from "../Story/StoryImage";
import StoryPropertiesList from "../Story/StoryPropertiesList";


const StoryTab = () => {
    const {currentFolderId, loading} = useSelector(state => ({
        loading: state.storyConfigState.isStoryConfigLoading,
        currentFolderId: state.manager.currentFolder.id,
    }), shallowEqual)

    if (currentFolderId === FOLDERS_ROOT_TREE_KEY) return <div />
    if (loading) return <div/>
    return (
        <div>
            <StoryHeader/>
            <Space size={20} direction="vertical" style={{width: '100%', padding: '30px 25px'}}>
                <Row gutter={30}>
                    <Col xl={7} lg={24}>
                        <div style={{border: '1px solid #F1F3F4', height: '360px', padding: '25px 20px'}}>
                            <StoryImage/>
                        </div>
                    </Col>
                    <Col xl={17} lg={24}>
                        <div style={{border: '1px solid #F1F3F4', height: '360px', overflowY: 'auto', padding: '15px'}}>
                            <StoryPropertiesList/>
                        </div>
                    </Col>
                </Row>
                <div>
                    <StoryRotationList/>
                </div>
            </Space>
        </div>
    );
}

export default StoryTab
