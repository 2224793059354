import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'

const configsAdapter = createEntityAdapter({
    selectId: model => model.guid
})

const configsList = createSlice({
    name: 'story/config-list',
    initialState: configsAdapter.getInitialState(),
    reducers: {
        addOne: configsAdapter.addOne,
        removeOne: configsAdapter.removeOne,
    }
})

const {reducer, actions} = configsList

export const configListActions = actions

export const persistConfig = {
    key: 'story-config-list',
    storage: storage,
}

export const storyConfigListReducer = persistReducer(persistConfig, reducer)
