export const treeMap = ({children = [], name, id,  ...obj}) => ({
    ...obj,
    title: name,
    key: id,
    children: children.map(treeMap)
})

export const countFormatter = (n, titles) => {
    const form = titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
    return `${n} ${form}`;
}

export const randomKey = (length) => Math.random().toString(36).substr(2, length)
