import React, {useCallback, useMemo} from "react";
import {Button, Card, Col, Image, List, Row, Space, Typography} from "antd";
import {DeleteOutlined, FolderOpenTwoTone} from "@ant-design/icons";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {TextStyled} from "../../styled/Typography";
import TimeTarget from "../../shared/TimeTarget";
import {
    storyConfigUpdateRotationProperty,
    storyConfigAddRotationProperty,
    storyConfigRemoveRotationProperty,
    rotationsSelectors,
    propertiesSelectors
} from "../../../redux/modules/story-config/story-config-rotations";
import {MinusButton, PlusButton} from "../../shared/Buttons";
import AddCreative from "../../shared/AddCreative";
import {
    selectContentCreativeById,
    selectContentFoldersById,
} from "../../../redux/modules/story-config/story-config-content";

const StoryRotationList = () => {
    const {configRotationsIds} = useSelector(state => ({
        configRotationsIds: rotationsSelectors.selectIds(state)
    }), shallowEqual)
    return (
        <List
            itemLayout="vertical"
            bordered={false}
            split={false}
            dataSource={configRotationsIds}
            renderItem={id => <RotationItem rotationId={id}/>}
        />
    )
}

const RotationItem = ({rotationId}) => {
    const rotation = useSelector(state => rotationsSelectors.selectById(state, rotationId), shallowEqual)

    if (!rotation) return <List.Item/>
    const {name, propertyIds} = rotation

    return (
        <List.Item>
            <Card size="small">
                <Typography.Text>РОТАЦИЯ: {name}</Typography.Text>
                <List
                    itemLayout="vertical"
                    split={false}
                    dataSource={propertyIds}
                    renderItem={id => <PropertyItem id={id} ids={propertyIds} rotationId={rotationId}/>}
                />
            </Card>
        </List.Item>
    )
}

const PropertyItem = ({id, ids, rotationId}) => {
    const {property} = useSelector(state => ({
        property: propertiesSelectors.selectById(state, id)
    }), shallowEqual)

    if (!property) return <List.Item/>

    const {creatives, folders} = property

    return (
        <List.Item>
            <Space size={20} direction="vertical" style={{border: '2px solid #D7F0FE', padding: '10px', width: '100%'}}>
                <Row gutter={20}>
                    <Col span={12}>
                        <PropertyCreativeActions
                            propertyId={id}
                            folders={folders} creatives={creatives}
                        />
                    </Col>
                    <Col span={12}>
                        <PropertyActions
                            property={property} propertyId={id} propertyIds={ids}
                            rotationId={rotationId}
                        />
                    </Col>
                </Row>
                <InlineList style={{overflowX: 'auto'}}>
                    <InlineList>
                        {folders.map(id => <FolderItem key={id} folderId={id}/>)}
                    </InlineList>
                    <InlineList>
                        {creatives.map(id => <CreativeItem key={id} creativeId={id}/>)}
                    </InlineList>
                </InlineList>
            </Space>
        </List.Item>
    )
}

const PropertyActions = ({propertyId, propertyIds, property, rotationId}) => {
    const {target} = property
    const dispatch = useDispatch()
    const applyTarget = (target) => {
        dispatch(storyConfigUpdateRotationProperty({
            propertyId,
            changes: {target}
        }))
    }
    const addProperty = () => {
        dispatch(storyConfigAddRotationProperty({
            rotationId, propertyIds,
            property: {...property, creatives: [], folders: []}
        }))
    }
    const canRemoveProperty = propertyIds.length > 1 && target !== null
    const removeProperty = () => {
        dispatch(storyConfigRemoveRotationProperty({
            rotationId, propertyIds, propertyId
        }))
    }
    return (
        <Space size={10} align="center">
            <TimeTarget initialTarget={target} apply={applyTarget}/>
            <Space size={5} style={{height: '100%'}}>
                <PlusButton onClick={addProperty}/>
                <MinusButton disabled={!canRemoveProperty} onClick={removeProperty}/>
            </Space>
            <TextStyled style={{color: '#0D5CAA'}}>
                {target === null ? 'БЕЗ ОГРАНИЧЕНИЙ' : 'ТАРГЕТИНГ НАСТРОЕН'}
            </TextStyled>
        </Space>
    )
}

const PropertyCreativeActions = ({propertyId, folders, creatives}) => {
    const dispatch = useDispatch()
    const addCreative = foldersIds => {
        dispatch(storyConfigUpdateRotationProperty({
            propertyId,
            changes: {
                folders: foldersIds,
            }
        }))
    }
    return (
        <Space size={15}>
            <AddCreative
                selectedFolders={folders}
                selectedCreative={creatives}
                apply={addCreative}
            />
            <Button icon={<DeleteOutlined/>}/>
        </Space>
    )
}

const FolderItem = ({folderId}) => {
    const {folderData} = useSelector(state => ({
        folderData: selectContentFoldersById(state, folderId),
    }), shallowEqual)
    return (
        <Card key={folderId} size="small" bordered={false}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{border: '0.5px solid #F3F3F5', borderRadius: '5px'}}>
                    <FolderOpenTwoTone
                        twoToneColor="#8DD4FC"
                        style={{fontSize: '65px', width: '95px'}}
                    />
                </div>
                <TextStyled style={{marginTop: '5px', maxWidth: '95px'}} ellipsis>{folderData?.name}</TextStyled>
            </div>
        </Card>
    )
}

const CreativeItem = ({creativeId}) => {
    const {creativeData} = useSelector(state => ({
        creativeData: selectContentCreativeById(state, creativeId),
    }), shallowEqual)
    return (
        <Card size="small" key={creativeId} bordered={false}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Image width={95} height={65} src={creativeData?.thumb}
                       preview={{
                           src: creativeData?.url
                       }}
                       style={{
                           border: '0.5px solid #F3F3F5',
                           borderRadius: '5px',
                           objectFit: 'cover'
                       }}
                />
                <TextStyled style={{marginTop: '5px', maxWidth: '95px'}} ellipsis>{creativeData?.file_name}</TextStyled>
            </div>
        </Card>
    )
}

const InlineList = ({children, style, ...props}) => (
    <Space
        size={20} direction="horizontal"
        style={{width: '100%', ...style}}
        {...props}
    >
        {children}
    </Space>
)


export default StoryRotationList
