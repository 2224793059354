import {Menu, Item, Separator, animation} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import React, {useCallback} from "react";
import {Form, Modal} from "antd";
import FolderForm from "../../shared/forms/FolderForm";
import {creativeAPI} from "../../../api/creativeAPI";
import {FOLDERS_ROOT_TREE_KEY} from "../../../utils/consts";
import {useDispatch} from "react-redux";
import DeferUploader from "../../shared/DeferUploader";
import {fetchCreativeTree} from "../../../redux/modules/creative";

const CreativeFoldersMenu = ({menuID,onCreativeSelect}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onAdd = useCallback(({props}) => {
        const {folderId} = props
        const handleSubmit = async ({name}) => {
            await creativeAPI.createFolder(folderId === FOLDERS_ROOT_TREE_KEY ? null : folderId, name)
            dispatch(fetchCreativeTree())
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Добавить папку',
            okText: 'Добавить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <FolderForm form={form} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name: 'Новая папка'
        })
    }, [form, dispatch])

    const onRename = useCallback(({props}) => {
        const {folderId, name} = props
        const handleSubmit = async ({name}) => {
            await creativeAPI.renameFolder(folderId, name)
            dispatch(fetchCreativeTree())
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Изменить название папки',
            okText: 'Изменить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <FolderForm form={form} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name
        })
    }, [form, dispatch])

    const onDelete = ({props}) => {
        const {folderId, name} = props
        const handleDelete = async () => {
            await creativeAPI.deleteFolder(folderId, name)
            dispatch(fetchCreativeTree())
            onCreativeSelect([FOLDERS_ROOT_TREE_KEY])
        }
        Modal.confirm({
            title: 'Удалить папку?',
            content: 'Все вложенные папки также будут удалены',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: handleDelete,
        })
    }

    const onFileUpload = ({props}) => {
        const {folderId, name} = props
        const handleUpload = async (file) => {
            const formData = new FormData()
            formData.append('content_file', file)
            return creativeAPI.addCreative(folderId, formData)
        }

        const handleUpdate = () => {
            modal.destroy()
            onCreativeSelect([folderId])
        }

        const modal = Modal.info({
            title: 'Добавить креатив',
            centered: true,
            maskClosable: true,
            mask: false,
            okButtonProps: {style: {display: 'none'}},
            content: <DeferUploader handleUpload={handleUpload} handleUpdate={handleUpdate}/>,
            icon: '',
        })
    }

    return (
        <Menu id={menuID} animation={animation.fade}>
            <Item onClick={onAdd}>
                Добавить папку
            </Item>
            <Separator/>
            <Item onClick={onRename}>
                Переименовать папку
            </Item>
            <Separator/>
            <Item onClick={onDelete}>
                Удалить папку
            </Item>
            <Separator/>
            <Item onClick={onFileUpload}>
                Добавить креатив
            </Item>
        </Menu>
    )
}

export default CreativeFoldersMenu
