import {Menu, Item, Separator, animation} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import React, {useCallback} from "react";
import {Form, Modal} from "antd";
import FolderForm from "../../../shared/forms/FolderForm";
import {FOLDERS_ROOT_TREE_KEY} from "../../../../utils/consts";
import {useDispatch} from "react-redux";
import {storyAPI} from "../../../../api/storyAPI";
import {fetchStoryTree} from "../../../../redux/modules/story-tree";
import StoryFormCreate from "../forms/StoryFormCreate";

const StoryFoldersMenu = ({menuID, onStorySelect}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onFolderAdd = useCallback(({props}) => {
        const {folderId, type} = props
        const handleSubmit = async ({name}) => {
            await storyAPI.createFolder(
                folderId === FOLDERS_ROOT_TREE_KEY ? null : folderId,
                {name}
            )
            dispatch(fetchStoryTree())
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Добавить папку',
            okText: 'Добавить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <FolderForm form={form} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name: 'Новая папка'
        })
    }, [form, dispatch])

    const onStoryAdd = useCallback(({props}) => {
        const {folderId} = props

        const handleSubmit = async ({name, template_id}) => {
            const data = await storyAPI.createStory(
                folderId === FOLDERS_ROOT_TREE_KEY ? null : folderId,
                {name, template_id})
                .then(({data}) => data?.data)
            await dispatch(fetchStoryTree())
            onStorySelect(
                [],
                {
                    node: data.story_guid ?
                        {type: 'story', key: data.story_guid, id: data.id}
                        :
                        {type: 'folder', key: FOLDERS_ROOT_TREE_KEY, id: FOLDERS_ROOT_TREE_KEY}
                }
            )
            modal.destroy()
        }
        const validate = (boolean) => modal.update({
            ...modal,
            okButtonProps: {disabled: !boolean}
        })
        const modal = Modal.confirm({
            title: 'Добавить сюжет',
            okText: 'Добавить',
            okButtonProps: {
                disabled: true
            },
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <StoryFormCreate form={form} validate={validate} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name: 'Новый сюжет',
            template_id: null,
        })
    }, [form, dispatch])

    const onFolderRename = useCallback(({props}) => {
        const {folderId, type, name} = props
        const handleSubmit = async ({name: newName}) => {
            await storyAPI.renameFolder(folderId, {name: newName, type})
            dispatch(fetchStoryTree())
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Изменить название папки',
            okText: 'Изменить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <FolderForm form={form} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name
        })
    }, [form, dispatch])

    const onFolderDelete = ({props}) => {
        const {folderId, type} = props
        const handleDelete = async () => {
            await storyAPI.deleteFolder(folderId, {type})
            dispatch(fetchStoryTree())
            onStorySelect(
                [],
                {
                    node: {type: 'folder', key: FOLDERS_ROOT_TREE_KEY, id: FOLDERS_ROOT_TREE_KEY}
                }
            )
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Удалить папку?',
            content: 'Все вложенные папки также будут удалены',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: handleDelete,
        })
    }

    return (
        <Menu id={menuID} animation={animation.fade}>
            <Item onClick={onFolderAdd}>
                Добавить папку
            </Item>
            <Separator/>
            <Item onClick={onStoryAdd}>
                Добавить сюжет
            </Item>
            <Separator/>
            <Item onClick={onFolderRename}>
                Переименовать папку
            </Item>
            <Separator/>
            <Item onClick={onFolderDelete}>
                Удалить папку
            </Item>
        </Menu>
    )
}

export default StoryFoldersMenu
