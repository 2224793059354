import {Button, DatePicker, Form, Input, InputNumber, Select, Typography} from "antd";
import React, {useState} from "react";
import styled from 'styled-components'
import {CalendarOutlined} from "@ant-design/icons";
import {DATE_FORMAT, PROPERTY_TYPES} from "../../../../utils/consts";
import {TextStyled} from "../../../styled/Typography";
import Switcher from '../../../shared/Switcher'

const halfWidth = {display: 'inline-block', width: '50%'}

const CreativeForm = ({form, handleSubmit, categories = []}) => {
    const options = categories.map(({name, id}) => ({label: name, value: id}))

    return (
        <Form form={form} layout="vertical" name="creative-property" onFinish={handleSubmit}>
            <Form.Item>
                <Label>КАТЕГОРИЯ</Label>
                <Form.Item name="category_id" noStyle wrapperCol={{span: 24}}>
                    <Select placeholder="Выбрать" options={options}/>
                </Form.Item>
            </Form.Item>
            <Form.Item wrapperCol={{span: 22}} style={halfWidth}>
                <Label>НАЗВАНИЕ СВОЙСТВА</Label>
                <Form.Item name="name" noStyle>
                    <Input placeholder="Текст"/>
                </Form.Item>
            </Form.Item>
            <Form.Item wrapperCol={{span: 24}} style={halfWidth}>
                <DynamicField valueName="value" typeName='value_type' form={form}/>
            </Form.Item>
        </Form>
    )
}

const DynamicField = ({valueName, typeName, form}) => {
    const [typeIndex, setTypeIndex] = useState(1)
    const handleChange = (value, value_type) => {
        form.setFieldsValue({[valueName]: value})
        form.setFieldsValue({[typeName]: value_type})
    }
    const ValueTypeIcon = () => (
        <Switcher currentKey={typeIndex}
                  switchComponents={[
                      {
                          switchKey: 1,
                          component: 'T'
                      },
                      {
                          switchKey: 2,
                          component: <CalendarOutlined/>
                      },
                      {
                          switchKey: 3,
                          component: '1'
                      }
                  ]}
                  defaultComponent={''}
        />
    )
    const ValueTypeField = () => (
        <Switcher currentKey={typeIndex}
                  switchComponents={[
                      {
                          switchKey: 1,
                          component: <Input onChange={({target}) => handleChange(target.value, PROPERTY_TYPES.STRING)}
                                            placeholder="Введите текст"/>
                      },
                      {
                          switchKey: 2,
                          component: <DatePicker
                              onChange={dateMoment => handleChange(dateMoment.format(DATE_FORMAT), PROPERTY_TYPES.DATE)}
                              style={{width: '100%'}} placeholder="Выберите дату"/>
                      },
                      {
                          switchKey: 3,
                          component: <InputNumber onChange={value => handleChange(value, PROPERTY_TYPES.FLOAT)}
                                                  style={{width: '100%'}} placeholder="Введите число"/>
                      }
                  ]}
                  defaultComponent={<Input onChange={({target}) => handleChange(target.value, PROPERTY_TYPES.STRING)}/>}
        />
    )
    const iterate = () => typeIndex === 3 ? setTypeIndex(1) : setTypeIndex(typeIndex + 1)

    return (
        <>
            <Label>
                ЗНАЧЕНИЕ
                <Button onClick={iterate} style={{marginLeft: '8px', borderColor: '#0D5CAA'}} size="small">
                    <Typography.Text style={{color: '#0D5CAA'}} strong>
                        <ValueTypeIcon/>
                    </Typography.Text>
                </Button>
            </Label>
            <Form.Item name={valueName} noStyle><ValueTypeField/></Form.Item>
            <Form.Item name={typeName} noStyle hidden><Input/></Form.Item>
        </>
    )
}

const Label = styled(TextStyled)`
  font-size: 14px;
  line-height: 28px;
  padding-bottom: 8px;
`


export default CreativeForm
