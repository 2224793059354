import {creativeAPI} from "../../api/creativeAPI";
import {treeMap} from "../../utils/common";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {setCurrentFolderId, setCurrentView} from "./manager";
import {VIEW_TABS} from "../../utils/consts";

const initialTreeState = {
    creativeTree: [],
    isCreativeTreeLoading: true,
}

export const fetchCreativeTree = () => async (dispatch) => {
    dispatch(creativeTreeLoading(true))
    const tree = await creativeAPI.foldersTree()
        .then(({data}) => data?.data)
        .catch(err => [])
    if (!Array.isArray(tree)) {
        dispatch(setCreativeTree([]))
        dispatch(creativeTreeLoading(false))
        return
    }
    const creativeTree = tree.map(treeMap)
    dispatch(setCreativeTree(creativeTree))
    dispatch(creativeTreeLoading(false))
}

const creativeTree = createSlice({
    name: 'creative/tree',
    initialState: initialTreeState,
    reducers: {
        setCreativeTree(state, action) {
            state.creativeTree = action.payload
        },
        creativeTreeLoading(state, action) {
            state.isCreativeTreeLoading = action.payload
        }
    },
})

const {actions: treeActions, reducer: treeReducer} = creativeTree
export const {
    setCreativeTree, creativeTreeLoading
} = treeActions

const initialListState = {
    creativeListRequestId: null,
    creativeList: [],
    isCreativeListLoading: true,
}

export const fetchCreativeList = createAsyncThunk(
    'creative/list/fetchCreativeList',
    async (folderId, {dispatch}) => {
        let response = []
        try {
            response = await creativeAPI.creativeList(folderId)
                .then(({ data }) => data?.data || [])
        } catch (error) {
            dispatch(setCurrentFolderId(null))
            dispatch(setCurrentView(VIEW_TABS.INITIAL_TAB))
        }
        return response
    }
)

const creativeList = createSlice({
    name: 'creative/list',
    initialState: initialListState,
    reducers: {},
    extraReducers: {
        [fetchCreativeList.pending]: (state, {meta}) => {
            state.isCreativeListLoading = true
            state.creativeListRequestId = meta.requestId
        },
        [fetchCreativeList.fulfilled]: (state, {meta, payload}) => {
            if (meta.requestId === state.creativeListRequestId) {
                state.creativeList = payload
                state.isCreativeListLoading = false
            }
        },
        [fetchCreativeList.rejected]: (state) => {
            state.creativeList = []
            state.isCreativeListLoading = false
        },
    }
})

const {reducer: listReducer} = creativeList

export const creativeTreeReducer = treeReducer
export const creativeListReducer = listReducer
