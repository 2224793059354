import {Menu, Item, Separator, animation} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import React, {useCallback} from "react";
import {Form, Modal} from "antd";
import FolderForm from "../../../shared/forms/FolderForm";
import {FOLDERS_ROOT_TREE_KEY} from "../../../../utils/consts";
import {useDispatch} from "react-redux";
import {storyAPI} from "../../../../api/storyAPI";
import {fetchStoryTree} from "../../../../redux/modules/story-tree";

const StoryRotationMenu = ({menuID,onStorySelect}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onRename = useCallback(({props}) => {
        const {folderId, name} = props
        const handleSubmit = async ({name}) => {
            await storyAPI.renameFolder(folderId, name)
            dispatch(fetchStoryTree())
            modal.destroy()
        }
        const modal = Modal.confirm({
            title: 'Изменить название сюжета',
            okText: 'Изменить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            content: (
                <FolderForm form={form} handleSubmit={handleSubmit}/>
            ),
            icon: '',
            onOk: form.submit,
        })
        form.setFieldsValue({
            name
        })
    }, [form, dispatch])

    const onDelete = ({props}) => {
        const {folderId, type} = props
        const handleDelete = async () => {
            await storyAPI.deleteStory(folderId)
            dispatch(fetchStoryTree())
            onStorySelect(
                [],
                {
                    node: {type: 'folder', key: FOLDERS_ROOT_TREE_KEY, id: FOLDERS_ROOT_TREE_KEY}
                }
            )
        }
        Modal.confirm({
            title: 'Удалить сюжет?',
            content: 'Все сохранения будут удалены',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: handleDelete,
        })
    }

    return (
        <Menu id={menuID} animation={animation.fade}>
            <Item onClick={onRename}>
                Переименовать сюжет
            </Item>
            <Separator/>
            <Item onClick={onDelete}>
                Удалить сюжет
            </Item>
        </Menu>
    )
}

export default StoryRotationMenu
