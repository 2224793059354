import {Tooltip} from "antd";
import Switcher from "../Switcher";
import {FolderOpenTwoTone, VideoCameraTwoTone} from "@ant-design/icons";
import React from "react";
import {countFormatter} from "../../../utils/common";
import {FOLDERS_ROOT_TREE_KEY} from "../../../utils/consts";

export const FolderTitle = ({nestedNumber, title, type, id}) => {
    const creativeCount = countFormatter(nestedNumber || 0, ['файл', 'файла', 'файлов'])
    const isRoot = id === FOLDERS_ROOT_TREE_KEY

    return (
        <div style={{display: 'flex', userSelect: 'none'}}>
            <Tooltip placement="right" title={!isRoot ? `В папке ${creativeCount}` : ''}>
            <span className='ant-tree-iconEle ant-tree-icon__customize'>
                <Switcher
                    switchComponents={[
                        {
                            switchKey: 'folder',
                            component: <FolderOpenTwoTone twoToneColor="#8DD4FC"/>
                        },
                        {
                            switchKey: 'story',
                            component: <VideoCameraTwoTone twoToneColor="#B3DD52"/>
                        }
                    ]}
                    defaultComponent={<FolderOpenTwoTone twoToneColor="#8DD4FC"/>}
                    currentKey={type}
                />
                </span>
                {title}
            </Tooltip>
        </div>
    )
}
