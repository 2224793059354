import instance from "./instance";
export const creativeAPI = {
    foldersTree: () => instance.post('api/v1/creams/folders/read'),
    foldersNameList: (id) => instance.post(`api/v1/creams/folders/list/${id}`),
    createFolder: (parentId, name) => instance.post(`api/v1/creams/folders/${parentId || 1}`, {name}),
    renameFolder: (folderId, name) => instance.post(`api/v1/creams/folders/${folderId}/rename`, {name}),
    deleteFolder: (folderId) => instance.delete(`api/v1/creams/folders/${folderId}`),
    creativeList: (folderId,filter) => instance.post(`api/v1/creams/creative/${folderId || ''}`, filter),
    addCreative: (folderId, data) => instance.post(`api/v1/creams/creative/write/${folderId}`, data),
    updateCreative: (creativeId, data) => instance.post(`api/v1/creams/creative/update/${creativeId}`, data),
    deleteCreative: creativeId => instance.delete(`api/v1/creams/creative/${creativeId}`),
    creativeProperties: (creativeId, properties) => instance.post(`api/v1/creams/properties/${creativeId}`, properties),
    deleteCreativeProperty: (creativeId, propertyId) => instance.delete(`api/v1/creams/properties/${creativeId}/${propertyId}`),
    category: () => instance.get('api/v1/creams/category')
}
