import React from "react";


const Tabs: React.FC<Props> = ({switchComponents,currentKey, defaultComponent = <div/>}) => {
    const found = switchComponents.find(sc => sc.switchKey === currentKey)
    if (found) return found.component
    return defaultComponent
}

type Props = {
    switchComponents: Array<TabComponent>,
    currentKey: string
    defaultComponent?: JSX.Element
}

type TabComponent = {
    switchKey: string,
    component: JSX.Element
}


export default Tabs
