import React, {useState, useEffect, useContext, createContext, useCallback} from 'react'
import {authAPI, setAuthToken} from "../api/authAPI";

const authContext = createContext({});
export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = () => {
    const [{user, isLogin}, setUser] = useState({user: null, isLogin: false})
    const [loading, setLoading] = useState(true)
    const login = data => {
        setLoading(true)
        return authAPI.login(data).then(({data}) => {
            setAuthToken(data.data.access_token)
            setUser({user, isLogin: true})
        }).then(me).catch(() => setLoading(false))
    }
    const logout = () => {
        setLoading(true)
        return authAPI.logout().then(() => {
            setAuthToken()
            setUser({user: null, isLogin: false})
            setLoading(false)
        }).catch(() => setLoading(false))
    }
    const me = useCallback(() => {
        setLoading(true)
        authAPI.profile().then(({data}) => {
            setUser({user: data.data, isLogin: true})
            setLoading(false)
        }).catch(() => setLoading(false))

        const interval = setInterval(() => authAPI.profile()
            .catch(err => {
                if (err.response.status === 401) {
                    clearInterval(interval)
                    window.location.reload()
                }
            }), 1000 * 60 * 10)
    }, [])

    useEffect(() => {
        me()
    }, [me])
    return {
        loading, user, isLogin,
        login, logout, me
    }
}

export const ProvideAuth = ({children}) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
