import ManagerPage from "./ManagerPage";
import React, {useEffect} from "react";
import ErrorBoundary from "../shared/ErrorBoundary";
import {useDispatch} from "react-redux";
import {resetManagerState} from "../../redux/modules/manager";

const routes = [
    {
        path: '/',
        exact: true,
        component: (props) => (
            <ErrorBoundary errorBody={<ManagerErrorBody/>}>
                <ManagerPage {...props}/>
            </ErrorBoundary>
        ),
    },
]

const ManagerErrorBody = () => {
    const dispatch = useDispatch()
    const resetManagerStore = () => {
        dispatch(resetManagerState())
    }
    useEffect(() => {
        resetManagerStore()
    }, [])
    return (<div style={{width: '100%', height: '100%'}}/>)
}

export default routes
