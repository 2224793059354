import {DatePicker, Form, Image, Input, InputNumber, Modal, Space, Switch, Typography} from "antd";
import React, {useState} from "react";
import {CalendarOutlined, CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {DATE_FORMAT, PROPERTY_TYPES} from "../../../../utils/consts";
import styled from "styled-components";
import Switcher from "../../../shared/Switcher";
import * as moment from "moment";
import {creativeAPI} from "../../../../api/creativeAPI";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchCreativeList} from "../../../../redux/modules/creative";
import {TextStyled} from "../../../styled/Typography";
import {DateField, IntField, TextField} from "../../../shared/forms/FormFields";

export const ImageInfo = ({name, url, thumb, format, date, enabled, creativeId}) => {
    const [checked,setChecked] = useState(enabled)
    const [loading,setLoading] = useState(false)
    const switchActivity = async (boolean) => {
        setLoading(true)
        await creativeAPI.updateCreative(creativeId, {enabled: boolean})
            .then(() => setChecked(boolean))
        setLoading(false)
    }
    return (
        <SpaceStyled align="start" size={20}>
            <SpaceStyled direction="vertical" size={8} align="center">
                <Image width={90} height={50} src={thumb}
                       preview={{
                           src: url
                       }}
                       style={{
                           border: '0.5px solid #F3F3F5',
                           borderRadius: '5px',
                           objectFit: 'cover'
                       }}
                />
                <SwitchStyled checked={checked} loading={loading} onChange={switchActivity}
                              checkedChildren="вкл" unCheckedChildren="выкл"
                />
            </SpaceStyled>
            <SpaceStyled direction="vertical" size={2}>
                <TextStyled as={Typography.Link} href={url} target="_blank" ellipsis
                            style={{maxWidth: '140px'}}>{name}</TextStyled>
                <TextStyled>{format}</TextStyled>
                <TextStyled>Создан: {moment(date).format(DATE_FORMAT)}</TextStyled>
            </SpaceStyled>
        </SpaceStyled>
    )
}

export const PropertyColumn = ({creativeId, property}) => {
    const {name, category_id, id, value, value_type} = property

    const [form] = Form.useForm()
    const handleChange = (field, fieldValue) => form.setFieldsValue({[field]: fieldValue})

    const [isEdit, setEdit] = useState(false)

    const {currentFolderId} = useSelector(state => ({
        currentFolderId: state.manager.currentFolder.id,
    }), shallowEqual)
    const dispatch = useDispatch()

    const onSubmit = async fields => {
        const {name, value} = fields
        const properties = {
            property_id: id, category_id, value_type,
            name, value
        }
        await creativeAPI.creativeProperties(creativeId, properties)
        dispatch(fetchCreativeList(currentFolderId))
    }

    const onSave = () => {
        form.submit()
        setEdit(false)
    }

    const onDelete = () => {
        const deleteProperty = async () => {
            await creativeAPI.deleteCreativeProperty(creativeId, id)
            dispatch(fetchCreativeList(currentFolderId))
        }
        Modal.confirm({
            title: 'Удалить свойство?',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            mask: false,
            icon: '',
            onOk: deleteProperty
        })
    }

    const NameProperty = () => isEdit ?
        <TextField defaultValue={name} fieldName="name" handleChange={handleChange}/>
        :
        <TextStyled $blockInline>{name}: </TextStyled>

    const DefaultProperty = () => isEdit ?
        <TextField defaultValue={value} fieldName="value" handleChange={handleChange}/>
        :
        <TextStyled $blockInline>{value}</TextStyled>

    const DateProperty = () => isEdit ?
        <DateField defaultValue={value} fieldName="value" handleChange={handleChange} format={DATE_FORMAT}/>
        :
        <Space>
            <CalendarOutlined/>
            <TextStyled $blockInline style={{minWidth: '64px'}}>{value}</TextStyled>
        </Space>

    const IntProperty = () => isEdit ?
        <IntField defaultValue={value} fieldName="value" handleChange={handleChange}/>
        :
        <TextStyled $blockInline>{value}</TextStyled>

    const Actions = () => (
        <FieldActions>
            {
                isEdit ?
                    <SaveOutlined onClick={onSave}/>
                    :
                    <EditOutlined onClick={() => setEdit(true)}/>
            }
            <CloseOutlined onClick={onDelete}/>
        </FieldActions>
    )

    return (
        <FormStyled name={`${creativeId}_${id}`} form={form} onFinish={onSubmit} initialValues={{name, value}}>
            <Space style={{width: '100%', justifyContent: 'space-between'}}>
                <NameProperty/>
                <Switcher
                    currentKey={value_type}
                    switchComponents={[
                        {
                            component: <DateProperty/>,
                            switchKey: PROPERTY_TYPES.DATE
                        },
                        {
                            component: <IntProperty/>,
                            switchKey: PROPERTY_TYPES.FLOAT
                        }
                    ]}
                    defaultComponent={<DefaultProperty/>}
                />
                <Actions/>
            </Space>
            <Form.Item hidden name="name"><Input/></Form.Item>
            <Form.Item hidden name="value"><Input/></Form.Item>
        </FormStyled>
    )

}

const FieldActions = styled(Space)`
  font-size: 12px;
  line-height: 14px;
  visibility: hidden;
`

const FormStyled = styled(Form)`
  &:hover ${FieldActions} {
    visibility: visible;
  }
`

const SpaceStyled = styled(Space)`
  .ant-space-item {
    display: flex;
  }
`

const SwitchStyled = styled(Switch)`
  &.ant-switch-checked {
    background-color: #A9D14B;
    &:focus {
      box-shadow: 0 0 0 2px rgb(169 209 75 / 20%);
    }
  }
`
