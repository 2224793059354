import React from "react";
import {useDispatch} from "react-redux";
import {useDrag} from 'react-dnd'
import {Button, Modal, Space, Table, Typography} from "antd";
import {DeleteFilled, DownloadOutlined, MenuOutlined} from "@ant-design/icons";
import {ImageInfo, PropertyColumn} from "./CreativeColumns";
import {creativeAPI} from "../../../../api/creativeAPI";
import {fetchCreativeList} from "../../../../redux/modules/creative";

const CreativeTable = ({loading, data, folderId, addProperty, categories}) => {
    const dispatch = useDispatch()
    const cellUpdate = (updateData, prevData) => {
        return JSON.stringify(prevData.columnData) !== JSON.stringify(updateData.columnData)
    }
    const propertyColumns = categories.map(({id: categoryId, name}) => {
        return ({
            title: name && name.toUpperCase(),
            key: `property_${categoryId}`,
            dataIndex: 'columnData',
            shouldCellUpdate: (update,prev) => cellUpdate(update, prev),
            render: ({properties, id}) => {
                const categoryProperties = Array.isArray(properties) ? properties.filter(p => p.category_id === categoryId) : []
                const renderProperty = property => <PropertyColumn key={property.id} creativeId={id}
                                                                   property={property}/>
                return (
                    <Space direction="vertical" size={5} style={{width: '100%', minWidth: '220px'}}>
                        {categoryProperties.map(renderProperty)}
                        <Button block onClick={() => addProperty(id, categoryId)}>Добавить</Button>
                    </Space>
                )
            }
        })
    })
    const onDelete = (creativeId) => {
        const deleteCreative = async () => {
            await creativeAPI.deleteCreative(creativeId)
            dispatch(fetchCreativeList(folderId))
        }
        Modal.confirm({
            title: 'Удалить креатив?',
            okType: 'danger',
            okText: 'Удалить',
            cancelText: 'Отменить',
            centered: true,
            maskClosable: true,
            icon: '',
            onOk: deleteCreative
        })
    }

    const DragHandle = ({columnData}) => {
        const [collected, drag, dragPreview] = useDrag({
            type: 'CREATIVE',
            item: columnData
        })
        return <MenuOutlined ref={drag} {...collected} style={{cursor: 'grab', color: '#999'}}/>
    }

    const columns = [
        {
            title: '',
            key: 'dragHandle',
            dataIndex: 'columnData',
            fixed: 'left',
            width: 46,
            shouldCellUpdate: (update,prev) => cellUpdate(update, prev),
            render: ({...columnData}) => (
                <DragHandle columnData={columnData}/>
            )
        },
        {
            title: 'КРЕАТИВЫ',
            fixed: 'left',
            key: 'item',
            dataIndex: 'columnData',
            shouldCellUpdate: (update,prev) => cellUpdate(update, prev),
            render: ({name, url, thumb, format, date_create, enabled,id}) => (
                <ImageInfo url={url} thumb={thumb}
                           name={name} format={format} date={date_create}
                           enabled={enabled} creativeId={id}
                />
            )
        },
        ...propertyColumns,
        {
            title: 'СТАТУС МОДЕРАЦИИ',
            width: 200,
            key: 'moderation_status'
        },
        {
            title: 'ДЕЙСТВИЯ',
            key: 'actions',
            width: 150,
            dataIndex: 'columnData',
            shouldCellUpdate: (update,prev) => cellUpdate(update, prev),
            render: ({url, id}) => (
                <Space style={{fontSize: '20px'}}>
                    <Typography.Link style={{color: '#B9C0CB'}} href={url}
                                     target="_blank"><DownloadOutlined/></Typography.Link>
                    <DeleteFilled style={{color: '#B9C0CB'}} onClick={() => onDelete(id)}/>
                </Space>
            )
        },
    ]

    return (
        <Table
            columns={columns}
            loading={loading}
            dataSource={data}
            scroll={{x: true}}
        />
    )
}

export default CreativeTable
