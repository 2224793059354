import axios from "axios";

const instance = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

export const errorHandlerInterceptor = (callback) => {
    return instance.interceptors.response.use(undefined, err => {
        if (typeof callback === 'function') callback(err)
        return Promise.reject(err)
    })
}

export const removeRequestInterceptor = (interceptor) => instance.interceptors.response.eject(interceptor)

export default instance
