import React from "react";
import {DatePicker, Input, InputNumber} from "antd";
import moment from "moment";

type TextFieldProps = {
    defaultValue: string,
    fieldName: string,
    handleChange: (fieldName: string, fieldValue: string) => void
}

export const TextField: React.FC<TextFieldProps> = ({defaultValue, handleChange, fieldName}) => (
    <Input defaultValue={defaultValue} size="small"
           onChange={({target}) => handleChange(fieldName, target.value)}
    />
)

type DateFieldProps = {
    defaultValue: string,
    fieldName: string,
    handleChange: (fieldName: string, fieldValue: string) => void,
    format: string
}

export const DateField: React.FC<DateFieldProps> = ({defaultValue, handleChange, fieldName, format}) => (
    <DatePicker size="small" allowClear={false}
                defaultValue={moment(defaultValue, format)} format={format}
                onChange={dateMoment => dateMoment && handleChange(fieldName, dateMoment.format(format))}
    />
)

type IntFieldProps = {
    defaultValue: number,
    fieldName: string,
    handleChange: (fieldName: string, fieldValue: number) => void,
}
export const IntField: React.FC<IntFieldProps> = ({defaultValue, fieldName, handleChange}) => (
    <InputNumber size="small" onChange={value => handleChange(fieldName, value)} defaultValue={defaultValue}
                 style={{width: '100%'}}/>
)
