import {createSlice} from "@reduxjs/toolkit";

export const initialConfigExtraState = {
    name: null,
    status: null,
    id: null,
    template: {
        id: null,
    },
    story: null,
    layout: null
}

const configExtra = createSlice({
    name: 'story/config/extra',
    initialState: initialConfigExtraState,
    reducers: {
        setExtra(state, actions) {
            const {payload} = actions
            const {
                name, status, id, template,
                date_create, date_update, guid, ...rest
            } = payload
            state.name = name
            state.status = status
            state.id = id
            state.template = template
            state.date_create = date_create
            state.date_update = date_update
            state.guid = guid
            Object.assign(state, rest)
        },
    }
})

export const {actions, reducer} = configExtra
