import React from "react";
import {useAuth} from "./hooks/use-auth";
import {Row} from "antd";
import WithAuthLayout from "./Components/Layout/WithAuthLayout";
import LoginPage from "./Components/pages/LoginPage";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import store, {persistor} from "./redux/store";
import routes from './Components/pages/routes'
import {Route} from "react-router-dom";
import './assets/antd-override.scss'

function App() {
  const {isLogin, loading} = useAuth()

  if (loading) return <div/>
  const pages = routes.map((routeProps, index) => <Route key={index} {...routeProps}/>)

  return (
    <Row className="App" style={{minHeight: '100vh'}}>
      {
        isLogin ? (
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <WithAuthLayout>
                      {pages}
                    </WithAuthLayout>
                  </PersistGate>
                </Provider>
            )
            : <LoginPage/>
      }
    </Row>
  );
}

export default App;
