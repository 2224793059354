import React, {useState} from "react";
import {Button, Space, Upload} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import InboxOutlined from "@ant-design/icons/lib/icons/InboxOutlined";

const {Dragger} = Upload

const DeferUploader = ({handleUpload, handleUpdate}) => {
    const [upload, setUpload] = useState([])
    const [loading, setLoading] = useState(false)
    const uploadProps = {
        multiple: true,
        beforeUpload: file => {
            setUpload(files => [...files, file])
            return false
        },
        onRemove: ({uid}) => {
            setUpload(files => files.filter(file => file.uid !== uid))
        },
        fileList: upload,
    }
    const handleClick = async () => {
        setLoading(true)
        await Promise.all(upload.map(file => handleUpload(file)))
        setLoading(false)
        handleUpdate()
    }
    return (
        <Space direction="vertical" size="middle" style={{width: '100%'}}>
            {
                upload.length > 0 ?
                    <Upload {...uploadProps} listType="picture-card">
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>ДОБАВИТЬ</div>
                        </div>
                    </Upload>
                    :
                    <Dragger {...uploadProps} showUploadList={false}>
                        <p className="ant-upload-drag-icon"><InboxOutlined/></p>
                        <p className="ant-upload-text">ЗАГРУЗИТЬ</p>
                    </Dragger>
            }
            <Button loading={loading} onClick={handleClick}>Добавить</Button>
        </Space>
    )
}

export default DeferUploader
