import instance from "./instance";

export const authAPI = {
    login: data => {
        return instance.post('/api/v1/admin_auth/admin_login', data)
    },
    logout: () => {
        return instance.get('/api/v1/auth/logout')
    },
    profile: () => {
        return instance.get(`/api/v1/auth/profile`)
    }
}


export const setAuthToken = token => {
    if (token) {
        localStorage.setItem('token', token)
        instance.defaults.headers.common['Authorization'] = token
    } else {
        localStorage.removeItem('token')
        delete instance.defaults.headers.common['Authorization']
    }
}
