import {useContextMenu} from "react-contexify";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    FOLDERS_ROOT_TREE_KEY,
    STORY_TREE_FOLDERS_MENU,
    STORY_TREE_ROTATION_MENU,
    VIEW_TABS
} from "../../../../utils/consts";
import {setCurrentFolder, setCurrentView} from "../../../../redux/modules/manager";
import React, {useEffect} from "react";
import {Tree} from "antd";
import {storyConfigActions} from "../../../../redux/modules/story";
import StoryFoldersMenu from "./StoryFoldersMenu";
import StoryRotationMenu from "./StoryRotationMenu";
import {FolderTitle} from "../../../shared/Tree";

const StoryFoldersTree = ({foldersTree, loading}) => {
    const {show: showFoldersMenu} = useContextMenu({id: STORY_TREE_FOLDERS_MENU})
    const {show: showRotationMenu} = useContextMenu({id: STORY_TREE_ROTATION_MENU})

    const dispatch = useDispatch()
    const {currentFolder, currentView} = useSelector(state => ({
        currentView: state.manager.currentView,
        currentFolder: state.manager.currentFolder,
    }), shallowEqual)
    const onStorySelect = (_, info) => {
        if (!info || !info.node) return null
        const {type, id, key} = info.node
        if (id === FOLDERS_ROOT_TREE_KEY) {
            dispatch(setCurrentFolder({id: FOLDERS_ROOT_TREE_KEY, storyUID: FOLDERS_ROOT_TREE_KEY, type: 'folder'}))
            dispatch(setCurrentView(VIEW_TABS.STORY_TAB))
        } else if (type === 'story') {
            dispatch(setCurrentFolder({id, storyUID: key, type: 'story'}))
            dispatch(setCurrentView(VIEW_TABS.STORY_TAB))
            dispatch(storyConfigActions.fetchStoryConfig(id))
        }
    }

    const onRightClick = ({event, node}) => {
        if (node.key !== FOLDERS_ROOT_TREE_KEY) {
            if (node.type === 'folder') showFoldersMenu(event, {
                props: {
                    folderId: node.id,
                    name: node.title,
                    type: node.type
                }
            })
            if (node.type === 'story') showRotationMenu(event, {
                props: {
                    folderId: node.id,
                    name: node.title,
                    type: node.type
                }
            })
        }
    }

    const rootDirectory = [{
        title: 'СЮЖЕТЫ',
        key: FOLDERS_ROOT_TREE_KEY,
        id: FOLDERS_ROOT_TREE_KEY,
        children: foldersTree,
        type: 'folder'
    }]

    useEffect(() => {
        if (currentView === VIEW_TABS.STORY_TAB) {
            onStorySelect(
                [],
                {
                    node: {
                        id: currentFolder.id,
                        key: currentFolder.storyUID,
                        type: currentFolder.type
                    }
                }
            )
        }
    }, [])
    if (loading) return <div/>
    return (
        <div style={{overflowX: 'auto'}}>
            <Tree
                defaultExpandAll
                selectedKeys={currentView === VIEW_TABS.STORY_TAB ? [currentFolder.storyUID] : null}
                className="directory-tree"
                showIcon={true}
                icon={''}
                style={{width: 'max-content', minWidth: '100%'}}
                titleRender={({key, title, ...data}) => (
                    <StoryFolderTitle id={key} title={title} data={data}/>
                )}
                treeData={rootDirectory}
                onSelect={onStorySelect}
                onRightClick={onRightClick}
            />
            <StoryFoldersMenu menuID={STORY_TREE_FOLDERS_MENU} onStorySelect={onStorySelect}/>
            <StoryRotationMenu menuID={STORY_TREE_ROTATION_MENU} onStorySelect={onStorySelect}/>
        </div>
    )
}

const StoryFolderTitle = ({id, title, data}) => {
    return (
        <FolderTitle
            title={title}
            nestedNumber={data?.number_creatives}
            type={data.type} id={id}
        />
    )
}

export default StoryFoldersTree
